import React from "react";

function TrashFilled({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.32"
        d="M3.20459 4.25L3.84987 15.2198C4.00578 17.8703 4.08374 19.1955 4.64942 20.2012C5.1474 21.0866 5.90322 21.7992 6.81627 22.2444C7.85347 22.75 9.181 22.75 11.8361 22.75H12.1639C14.819 22.75 16.1465 22.75 17.1837 22.2444C18.0968 21.7992 18.8526 21.0866 19.3506 20.2012C19.9163 19.1955 19.9942 17.8703 20.1501 15.2198L20.7954 4.25H3.20459Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.621 1.24992C10.657 1.24998 10.6932 1.25003 10.7298 1.25003H13.2702C13.3068 1.25003 13.343 1.24998 13.379 1.24992C13.8916 1.24916 14.3415 1.24848 14.7424 1.40615C15.0929 1.54402 15.4032 1.76768 15.6449 2.05663C15.9212 2.38709 16.0629 2.81412 16.2242 3.30066C16.2355 3.33479 16.247 3.36921 16.2585 3.40391L16.5406 4.25003H20H22C22.4142 4.25003 22.75 4.58582 22.75 5.00003C22.75 5.41424 22.4142 5.75003 22 5.75003H20H16.0173C16.0057 5.7503 15.9941 5.7503 15.9825 5.75003H8.01751C8.00594 5.7503 7.99433 5.7503 7.9827 5.75003H4H2C1.58579 5.75003 1.25 5.41424 1.25 5.00003C1.25 4.58582 1.58579 4.25003 2 4.25003H4H7.45943L7.74147 3.40391C7.75304 3.36921 7.76445 3.33479 7.77577 3.30067C7.93715 2.81412 8.07878 2.38709 8.35513 2.05663C8.59677 1.76768 8.90709 1.54402 9.25763 1.40615C9.65851 1.24848 10.1084 1.24916 10.621 1.24992ZM9.04057 4.25003H14.9594L14.8355 3.87825C14.6186 3.22743 14.5633 3.10155 14.4942 3.0189C14.4137 2.92258 14.3102 2.84803 14.1934 2.80207C14.0931 2.76263 13.9562 2.75003 13.2702 2.75003H10.7298C10.0438 2.75003 9.9069 2.76263 9.80663 2.80207C9.68979 2.84803 9.58635 2.92258 9.5058 3.0189C9.43668 3.10155 9.38144 3.22743 9.16449 3.87825L9.04057 4.25003ZM10 9.25003C10.4142 9.25003 10.75 9.58582 10.75 10V17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17V10C9.25 9.58582 9.58579 9.25003 10 9.25003ZM14 9.25003C14.4142 9.25003 14.75 9.58582 14.75 10V14C14.75 14.4142 14.4142 14.75 14 14.75C13.5858 14.75 13.25 14.4142 13.25 14V10C13.25 9.58582 13.5858 9.25003 14 9.25003Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TrashFilled;
