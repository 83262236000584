import React from "react";
import { Container, Row, Col } from "reactstrap";
import LogoMaia from "../../assets/images/logo/maaia_logo_500x500.png";

const NoDesktopTempUI = () => {
  document.title = "Error-500  | IA - Smart Assistant";

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} md={12} xl={12}>
                <div className="text-center">
                  <div>
                    <img src={LogoMaia} alt="logoMaia" style={{ objectFit: "cover", height: "100px", width: "100px" }} />
                  </div>
                  <div style={{ marginTop: "18px" }}>
                    <p style={{ fontSize: "18px" }}>Disponible uniquement sur écran large</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoDesktopTempUI;
