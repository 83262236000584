import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { map } from "lodash";

//i18n
import i18n from "../../../i18n";

import { withTranslation } from "react-i18next";

import languages from "../../../CommonData/languages";
import { createLog } from "../../../helpers/api_helper";
import { Account, Client } from "appwrite";

const LanguageDropdown = (props) => {
  const [singlebtn, setSinglebtn] = useState(false);
  const [selectLang, setselectLang] = useState("fr");
  const [userPrefs, setUserPrefs] = useState({});

  useEffect(() => {
    getUserPrefs();
    // Fonction pour mettre à jour la valeur lorsque l'événement est déclenché
    const handleValeurMiseAJour = (event) => {
      setselectLang(event.detail ?? "fr");
    };

    // Écouter l'événement personnalisé
    window.addEventListener("valeurMiseAJour", handleValeurMiseAJour);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("valeurMiseAJour", handleValeurMiseAJour);
    };
  }, [selectLang]);

  async function updateUserPrefs(userPrefs) {
    const client = new Client();

    const account = new Account(client);

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const promise = account.updatePrefs(userPrefs);

    return promise.then(
      function (response) {
        console.log("new userPrefs is ", response); // Success
        return response;
      },
      function (error) {
        console.log("error on userPrefs update ", error); // Failure
        return null;
      },
    );
  }

  async function getUserPrefs() {
    const client = new Client();
    const account = new Account(client);

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const promise = account.getPrefs();
    return await promise.then(
      async function (response) {
        console.log("userInfos is", response);
        setUserPrefs(response);
        i18n.changeLanguage(response.lang ?? "fr");
        localStorage.setItem("I18N_LANGUAGE", response.lang ?? "fr");
        //console.log({ response });

        setselectLang(response.lang ?? "fr");
      },
      function (error) {
        console.log(error);
      },
    );
  }
  const changeLanguageAction = async (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    const new_prefs = userPrefs;
    new_prefs["lang"] = lang;
    const response = await updateUserPrefs(new_prefs);
    setselectLang(lang);

    // Émettre un événement personnalisé
    const event = new CustomEvent("valeurMiseAJour", { detail: lang });
    window.dispatchEvent(event);

    // Créer le log
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    let logData = {
      eventCreatedAt: response.$createdAt,
      author_id: user_id,
      company_id: localStorage.getItem("companyId"),
      new_lang: lang,
    };
    let logdata = {
      event: "lang_updated",
      author_id: user_id,
      company_id: localStorage.getItem("companyId"),
      log_data: JSON.stringify(logData),
    };
    createLog(logdata);
  };
  return (
    <React.Fragment>
      <Dropdown isOpen={singlebtn} toggle={() => setSinglebtn(!singlebtn)} className="d-inline-block d-sm-inline-block">
        <DropdownToggle className="header-item waves-effect p-0" tag="button" style={{ backgroundColor: "transparent" }}>
          <img src={selectLang ? languages[selectLang].flag : languages["fr"].flag} alt="iAssistant" height="16" className="" />
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem key={key} onClick={() => changeLanguageAction(key)} className={`notify-item ${selectLang === key ? "active" : "none"}`}>
              <img src={languages[key].flag} alt="iAssistant" className="" height="12" width="24.38" />

              <span className="align-middle">{props.t(languages[key].label)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(LanguageDropdown);
