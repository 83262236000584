import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ zIndex: 1000 }}>
        <Container fluid={true}>
          <Row>
            <Col className="font-size-18 col-auto mx-auto font-primary">Maaia®</Col>
            {/* <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block simple-text font-primary">
                By SemeCloud
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
