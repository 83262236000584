import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import logo_maaia from "../../assets/images/maaia-logo.png";
import ratio_turnover from "../../assets/images/ratio_turnover.png";
import { withTranslation } from "react-i18next";
const Ratio = (props) => {
  let DashedLineData = null;

  const theMessages = props.messages;

  const [loading, setLoading] = useState(true);

  const [formattedDataList, setformattedDataList] = useState({
    series: [
      {
        name: "Ratio CA",
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#F10944"],
      xaxis: {
        type: "datetime",
        categories: [],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  });

  // Fonction pour agréger le nombre de messages par jour
  const aggregateMessagesByDay = (data) => {
    const aggregatedData = {};
    let theCount = 0;
    data.forEach((message) => {
      theCount += 1;
      const messageDate = new Date(message.message_date);
      const dayKey = messageDate.getTime();

      if (aggregatedData[dayKey]) {
        aggregatedData[dayKey].count = ((theCount * 7) / aggregatedData[dayKey].count).toFixed(0);
      } else {
        aggregatedData[dayKey] = {
          date: dayKey,
          count: (theCount * 7) / 1,
        };
      }
    });

    return Object.values(aggregatedData);
  };

  const handleDateChange = (theData) => {
    console.log("last messages", theData[theData.length - 1]);
    var data = theData;
    // Agréger le nombre de messages par jour
    const aggregatedData = aggregateMessagesByDay(data);

    // Mettre à jour le state avec les données agrégées
    setformattedDataList((prevData) => ({
      ...prevData,
      series: [
        {
          name: "Ratio C.A",
          data: aggregatedData.map((dataPoint) => ({
            x: dataPoint.date,
            y: dataPoint.count,
          })),
        },
      ],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: aggregatedData.map((dataPoint) => dataPoint.date),
        },
      },
    }));
    // setformattedDataList(finalChartData);

    setLoading(false);
  };
  useEffect(() => {
    handleDateChange(theMessages);
  }, [theMessages]);

  return (
    <React.Fragment>
      {formattedDataList && formattedDataList.series[0].data.length > 0 ? (
        <ReactApexChart
          options={formattedDataList.options}
          series={formattedDataList.series}
          type="area"
          height="235"
          className="apex-charts font-primary"
        />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + ratio_turnover + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "138%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}>
          <div className="font-primary font-size-22 color-black mt-5">{props.t("En attente de données")}</div>
          <img src={logo_maaia} height={40} />
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Ratio);
