import { SHOW_AGENT_STATUS, SHOW_CREDIT } from "./actionTypes";

const initialState = {
  agentStatus: false,
  credit: false,
};

const GeneralStateRedux = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_AGENT_STATUS:
      return {
        ...state,
        agentStatus: action.payload,
      };
    case SHOW_CREDIT:
      return {
        ...state,
        credit: action.payload,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GeneralStateRedux;
