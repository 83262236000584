import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

const AnalyseMessageSavJour = (props) => {
  let barchartData = null;
  const [messageCountByDayData, setMessageCountByDayData] = useState(undefined);
  const theMessages = props.messages;
  const dataMap = new Map();

  const handleDateChange = (theData) => {
    var data = theData;
    console.log("AnalyseMessageSavJour", data);
    const messageCountByDay = [0, 0, 0, 0, 0, 0, 0]; // Dimanche, Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi

    const messages = data;

    const daysOfWeek = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

    dataMap.clear();
    daysOfWeek.forEach((day, index) => {
      dataMap.set(index.toString(), {
        day: daysOfWeek[index],
        val: 0,
        width: (0 * 100) / (messages.length === 0 ? 1 : messages.length),
        backgroundColor: "#FFA412",
      });
    });

    messages.forEach((message) => {
      const messageDateEpoch = message.message_date;
      const messageDate = new Date(messageDateEpoch);
      const jourDeLaSemaineIndex = messageDate.getDay();
      messageCountByDay[jourDeLaSemaineIndex]++;
      console.log(`day of message from ${messageDate} is ${jourDeLaSemaineIndex} with name ${daysOfWeek[jourDeLaSemaineIndex]}`);

      if (dataMap.has(messageDate.getDay().toString())) {
        const theVal = dataMap.get(messageDate.getDay().toString()).val + 1;
        dataMap.set(messageDate.getDay().toString(), {
          day: daysOfWeek[jourDeLaSemaineIndex],
          val: theVal,
          width: (theVal * 100) / messages.length,
          backgroundColor: "#FFA412",
        });
      } else {
        const theVal = 1;

        dataMap.set(messageDate.getDay().toString(), {
          day: daysOfWeek[jourDeLaSemaineIndex],
          val: theVal,
          width: (theVal * 100) / messages.length,
          backgroundColor: "#FFA412",
        });
      }
    });

    console.log("total is", messages.length); // Affichez le tableau résultant
    console.log("messageCountByDay", dataMap); // Affichez le tableau résultant
    setMessageCountByDayData(dataMap);
  };

  const getItemData = (item_key, the_statsData) => {
    return {
      day: the_statsData.get(item_key).day.slice(0, 3) + ".",
      val: the_statsData.get(item_key).val,
      width: the_statsData.get(item_key).width.toFixed(0),
      backgroundColor: "#FFA412",
    };
  };

  //
  const newData = [
    {
      text: "L",
      width: "10%",
      backgroundColor: "#FFA412",
    },
    {
      text: "M",
      width: "1%",
      backgroundColor: "#FFA412",
    },
    {
      text: "M",
      width: "1%",
      backgroundColor: "#FFA412",
    },
    {
      text: "J",
      width: "1%",
      backgroundColor: "#FFA412",
    },
    {
      text: "V",
      width: "1%",
      backgroundColor: "#FFA412",
    },
    {
      text: "S",
      width: "1%",
      backgroundColor: "#FFA412",
    },
    {
      text: "D",
      width: "1%",
      backgroundColor: "#FFA412",
    },
  ];

  useEffect(() => {
    handleDateChange(theMessages);
  }, [theMessages]);

  return (
    <React.Fragment>
      {/* <ReactApexChart
                options={barchartData.options}
                series={barchartData.series}
                type="bar"
                height="350"
                className="apex-charts"
            /> */}
      {messageCountByDayData && Array.from(messageCountByDayData.keys().length > 0)
        ? Array.from(messageCountByDayData.keys()).map((item, index) => (
            <>
              <div
                key={index}
                className="d-flex align-items-center"
                style={{ borderBottom: index !== Array.from(messageCountByDayData.keys()).length - 1 ? "2px dashed #E6E7E9" : "none" }}>
                <div className="col-xxl-2 col-xl-2 customGraph_yAxis__value simple-text font-primary">
                  {props.t(getItemData(item, messageCountByDayData).day)}
                </div>
                <div className="customGraph_bar__container col-xxl-9 col-xl-8">
                  <div
                    key={index}
                    style={{
                      backgroundColor: getItemData(item, messageCountByDayData).backgroundColor,
                      width: getItemData(item, messageCountByDayData).width + "%",
                      whiteSpace: "nowrap", // Empêche le texte de passer à la ligne
                    }}
                    className="customGraph_bar"></div>
                </div>

                <div
                  className="col-xxl-1 col-xl-2"
                  style={{
                    color: item.backgroundColor,
                    textAlign: "end",
                    paddingRight: "0",
                  }}>
                  {getItemData(item, messageCountByDayData).width + "%"}
                </div>
                <div className="divider"></div>
              </div>
            </>
          ))
        : newData.map((item, index) => (
            <>
              <div
                key={index}
                className="d-flex align-items-center"
                style={{ borderBottom: index !== newData.length - 1 ? "2px dashed #E6E7E9" : "none" }}>
                <div className="col-xxl-1 col-xl-1 customGraph_yAxis__value simple-text font-primary">{props.t(item.text)}</div>
                <div className="customGraph_bar__container col-xxl-10 col-xl-9">
                  <div
                    key={index}
                    style={{
                      backgroundColor: item.backgroundColor,
                      width: item.width,
                      whiteSpace: "nowrap", // Empêche le texte de passer à la ligne
                    }}
                    className="customGraph_bar"></div>
                </div>

                <div
                  className="col-xxl-1 col-xl-2"
                  style={{
                    color: item.backgroundColor,
                    textAlign: "end",
                    paddingRight: "0",
                  }}>
                  {item.width}
                </div>
                <div className="divider"></div>
              </div>
            </>
          ))}
    </React.Fragment>
  );
};

export default withTranslation()(AnalyseMessageSavJour);
