import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Row } from "reactstrap";
import progression_bar from "../../assets/images/progression-bar.png";
import logo_maaia from "../../assets/images/maaia-logo.png";
import { withTranslation } from "react-i18next";
const ProgressionBar = (props) => {
  // const data = {
  //   labels: ["2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"],
  //   datasets: [
  //     {
  //       label: "",
  //       lineTension: 0.2,
  //       borderColor: ["#FF7049"],
  //       borderWidth: 3,
  //       fill: false,
  //       pointBackgroundColor: "#ffffff",
  //       pointBorderColor: "#FF7049",
  //       data: [20, 80, 40, 110, 60, 140, 80, 110],
  //     },
  //     {
  //       label: "",
  //       lineTension: 0.2,
  //       backgroundColor: "rgba(235, 239, 242, 0)",
  //       borderColor: ["#563BFF"],
  //       borderWidth: 3,
  //       fill: false,
  //       pointBackgroundColor: "#ffffff",
  //       pointBorderColor: "#563BFF",
  //       data: [80, 140, 100, 170, 120, 200, 140, 170],
  //     },
  //   ],
  // };
  const [data, setData] = useState(null);
  var option = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            // max: 200,
            // min: 0,
            stepSize: 50,
            // zeroLineColor: "#7b919e",
            // borderDash: [3, 3],
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };
  return data ? (
    <div
      style={{
        position: "relative",
        display: "grid",
        gridTemplateRows: "minmax(0,3fr)",
        height: "86%",
      }}>
      <Line data={data} options={option} />
    </div>
  ) : (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        backgroundImage: "url(" + progression_bar + ")",
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}>
      <div className="font-primary font-size-22 color-black mt-5">{props.t("En attente de données")}</div>
      <img src={logo_maaia} height={40} />
    </div>
  );
};

export default withTranslation()(ProgressionBar);
