import React, { useEffect, useRef, useState } from "react";
import "./chat.scss";
import menu_icon from "../../assets/images/icons/icons8-menu-2-32.png";
import down_arrow_icon from "../../assets/images/icons/icons8-flèche-développer-30.png";
import join_file_icon from "../../assets/images/icons/icons8-trombone-24.png";
import emoji_icon from "../../assets/images/icons/icons8-lol-24.png";
import logo_ai_assistant from "../../assets/images/icons/icons8-bavarder-48.png";
import sent_icon from "../../assets/images/icons/icons8-envoyé-30.png";
import robot from "../../assets/images/icons8-bot-48.png";
import CrossOutlined from "../../assets/images/icons/CrossOutlined";
import MinusOutlined from "../../assets/images/icons/MinusOutlined";
import MoreOutlined from "../../assets/images/icons/MoreOutlined";
import SendOutlined from "../../assets/images/icons/SendOutlined";
import PlusRecOutlined from "../../assets/images/icons/PlusRecOutlined";
import EmotionHappyOutlined from "../../assets/images/icons/EmotionHappyOutlined";
function Chat() {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [conversationList, setConversationList] = useState([]);
  const [conversationListHTML, setConversationListHTML] = useState([
    { type: "in", content: "Bonjour, dites-moi comment je peux vous être utile aujourd'hui" },
  ]);
  const [theInput, setTheInput] = useState("");
  function adjustHeight(e) {
    e.target.style.height = "26px";
    e.target.style.height = `${e.target.scrollHeight}px`;
  }
  function showBox() {
    setIsOpen(!isOpen);
  }

  const getClass = (item) => {
    if (item.type === "in") {
      return "chat_singleMsg chat_entrant";
    } else if (item.type === "out") {
      return "chat_singleMsg chat_sortant";
    }
    return "";
  };

  // console.log("conversationListHTML", conversationListHTML);
  // console.log("conversationList_", conversationList);

  async function sendNewMessage() {
    //const apiKey = process.env.SHOPIFY_API_KEY;
    //const apiSecret = process.env.API_SECRET;
    //// console.log("my key is"+$apiKey);

    //const messageInputField = document.querySelector(".text-box");
    //let newMessageHTML = messageInputField.innerHTML;

    // console.log("theInput is", theInput);
    if (!theInput || theInput.length === 0) return;
    let newMessage = theInput
      .replace(/<div>|<br.*?>/gi, "\n")
      .replace(/<\/div>/g, "")
      .trim();
    // console.log("newMessage is", newMessage);

    let conversationListHTML_Copy = [...conversationListHTML];
    conversationListHTML_Copy.push({ type: "out", content: newMessage });
    setConversationListHTML([...conversationListHTML_Copy]);

    /*
  // prepare li node for new message and add to message list
  var the_message_li_element = document.createElement("li");
  the_message_li_element.classList.add("singleMsg");
  the_message_li_element.classList.add("sortant");
  the_message_li_element.innerHTML = theInput.value;

  messagesDiv.appendChild(the_message_li_element);
  */

    // clean out old message
    setTheInput("");

    // focus on input
    //messageInputField.focus();

    const showChatBubble = (status) => {
      setShow(status);
    };

    setTimeout(function () {
      showChatBubble(true);
    }, 3000);

    setConversationHistory(conversationHistory + "Admin: " + newMessage + "\n\nAI ");
    /*
  if (conversationList.value.length == 0) {
    conversationList.value.push({
      role: "assistant",
      content: "Bonjour, comment puis-je vous aider ?",
    });
  }
  */

    let conversationList_Copy = [...conversationList];
    conversationList_Copy.push({ role: "user", content: newMessage });
    setConversationList(conversationList_Copy);

    try {
      const response = await fetch(
        "https://ai.semecloud.tech/email-ia-dialog",
        //"http://localhost:50000/email-ia-dialog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: conversationList }),
          //   body: test_3,
        }
      );
      const data = await response.json();
      // console.log("data_", data);
      if (response.status !== 200) {
        throw data.error || new Error(`Request failed with status ${response.status}`);
      }

      showChatBubble(false);

      setConversationHistory(conversationHistory + data.result);
      conversationList_Copy.push({ role: "assistant", content: data.result });
      setConversationList(conversationList_Copy);
      conversationListHTML_Copy.push({ type: "in", content: data.result });
      setConversationListHTML([...conversationListHTML_Copy]);

      /*
    the_message_li_element = document.createElement("li");
    the_message_li_element.classList.add("singleMsg");
    the_message_li_element.classList.add("entrant");
    the_message_li_element.innerText = data.result;

    messagesDiv.appendChild(the_message_li_element);
    */
    } catch (error) {
      showChatBubble(false);

      if (error.response) {
        console.error(error.response.status, error.response.data);
      } else {
        console.error(`Error with OpenAI API request: ${error.message}`);
      }
    }
  }

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(scrollToBottom, [conversationListHTML.length, isOpen]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "90px",
        right: "10vh",
        zIndex: "99999",
      }}
    >
      {isOpen && (
        <>
          <div className="chat-content">
            <div
              className="chat-header"
              style={{ color: "black" }}
            >
              <div
                className="chat-header_left"
                style={{ cursor: "pointer" }}
              >
                {/* <MoreOutlined width={20} /> */}
              </div>
              <div className="chat-header_center d-flex gap-2">
                <div className="chat_profil">
                  <img
                    src="https://img.icons8.com/external-becris-flat-becris/50/000000/external-user-avatars-becris-flat-becris.png"
                    alt="user-img"
                    height="28px"
                    width="28px"
                  />
                </div>
                <span className="chat_nom my-auto">Assistant</span>
              </div>
              <div className="chat-header_right">
                <span style={{ cursor: "pointer" }}>
                  <MinusOutlined
                    width={20}
                    height={20}
                    className={"me-1"}
                    style={{ marginBottom: "-22%" }}
                  />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    showBox();
                  }}
                >
                  <CrossOutlined
                    width={20}
                    height={20}
                  />
                </span>
              </div>
              {/* <div className="topIcon" style={{ position: "absolute", position: "absolute", right: "15%" }}>
                <img src={menu_icon} alt="menu icon" style={{ width: "20px", height: "20px" }} />
              </div>
              <div className="chat_profil">
                <img
                  src="https://img.icons8.com/external-becris-flat-becris/50/000000/external-user-avatars-becris-flat-becris.png"
                  alt="user-image"
                />
              </div>
              <div className="wrapDiv">
                <div className="aboveNom">Discuter avec</div>
                <div className="nom">Assistant</div>
              </div>

              <div
                className="topIcon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showBox();
                }}>
                <img style={{ height: "16px", width: "16px", position: "absolute", right: "5%" }} src={down_arrow_icon} alt="down arrow icon" />
              </div> */}
            </div>
            <div className="d-flex messages">
              <ul className="chat_messages ps-0">
                {conversationListHTML.map((item, index) => (
                  <div
                    key={index}
                    className={getClass(item) + " d-flex mx-3 my-1"}
                  >
                    {item.type === "in" && (
                      <div
                        className="chat_profil d-flex"
                        style={{ height: "fit-content" }}
                      >
                        <img
                          src="https://img.icons8.com/external-becris-flat-becris/50/000000/external-user-avatars-becris-flat-becris.png"
                          alt="user-img"
                          height="20px"
                          width="20px"
                        />
                      </div>
                    )}
                    <li>{item.content}</li>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </ul>
            </div>
            <div className="chat_footer">
              <div className="the_input d-flex align-items-center mx-3 mb-4 w-100">
                <textarea
                  onInput={(e) => {
                    setTheInput(e.target.value);
                    adjustHeight(e);
                  }}
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter") sendNewMessage();
                  // }}
                  className="text_area"
                  name="the_prompt"
                  placeholder="Écrire..."
                  style={{
                    flex: "1",
                    minHeight: "26px",
                    height: "26px",
                    maxHeight: "64px",
                    overflowY: "auto",
                  }}
                  value={theInput}
                />
                <button
                  className="action_button"
                  onClick={(e) => {}}
                >
                  <EmotionHappyOutlined
                    width={20}
                    height={20}
                  />
                </button>
                <button
                  className="action_button"
                  onClick={(e) => {}}
                >
                  <PlusRecOutlined
                    width={20}
                    height={20}
                  />
                </button>
                <button
                  id="send_button"
                  className="action_button"
                  style={{ color: !theInput && "#dcdcdc" }}
                  onClick={(e) => sendNewMessage()}
                >
                  <SendOutlined
                    width={20}
                    height={20}
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {!isOpen && (
        <div
          className="bull"
          onClick={(e) => showBox()}
        >
          <img
            className="robot"
            src={robot}
            alt="robot"
          />
        </div>
      )}
    </div>
  );
}
export default Chat;
