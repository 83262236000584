import { SHOW_AGENT_STATUS, SHOW_CREDIT } from "./actionTypes";

// agentStatus
export const isActivatedAgentStatus = (active) => ({
  type: SHOW_AGENT_STATUS,
  payload: active,
});
export const isCreditAvailable = (active) => ({
  type: SHOW_CREDIT,
  payload: active,
});
