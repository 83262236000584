import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Client, Databases } from "appwrite";

const ShopifyOauth2 = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const code = urlParams.get("code");

  const [message, setMessage] = useState({ type: null, text: null });
  const [loading, setLoading] = useState(null);
  const onLoadingChanged = (val) => {
    setLoading(val);
  };
  async function updateCompanyInfos() {
    onLoadingChanged(true);

    const client = new Client();

    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const promise = databases.updateDocument("default", "client_infos", localStorage.getItem("companyId"), { shopify_token: code });

    promise.then(
      async function (response) {
        // console.log({ response });
        onLoadingChanged(false);
        setMessage({ type: "success", message: "Enregistrement réusssi." });
        navigate(`/settings`, {
          replace: true,
          state: { auth: "shopify", type: "success", message: "Enregistrement réusssi." },
        });
      },
      function (error) {
        console.log(error); // Failure
        onLoadingChanged(false);
        setMessage({ type: "error", message: "Erreur lors de l'enregistrement, veuillez réessayer" });
        navigate(`/settings`, {
          replace: true,
          state: { auth: "shopify", type: "error", message: "Erreur lors de l'enregistrement, veuillez réessayer" },
        });
      },
    );
  }
  useEffect(() => {
    // console.log({ code });
    if (code) {
      onLoadingChanged(true);
      updateCompanyInfos();
    } else {
      navigate(`/settings`);
    }
  }, []);

  return (
    <>
      {code && (
        <React.Fragment>
          <div className="bg-pattern" style={{ height: "100vh" }}>
            <div className="account-pages pt-5">
              <Container>
                <Row className="w-100 justify-content-center">
                  <Col lg={12} md={6} xl={6}>
                    <Card className="mt-5">
                      <CardBody className="p-4">
                        <div className="">
                          <div className="text-center">
                            <Link to="/" className="">
                              <div
                                className=""
                                style={{
                                  marginTop: "3vh",
                                  color: "#151d48",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "54px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "140%",
                                }}>
                                Connexion...
                              </div>
                            </Link>
                          </div>
                          <form className="form-horizontal" action="#">
                            <Row>
                              <Col className="d-flex justify-content-center">
                                <i className="fab fa-shopify" style={{ fontSize: "100px", color: "#96BF48" }} />
                              </Col>
                              <Col className="d-flex flex-column align-items-center" md={12}>
                                {loading && <span className="spinner-border spinner-border-xxl me-2 mt-4" role="status" aria-hidden="true"></span>}
                                {loading && !message.text && (
                                  <p className="mt-3 font-primary text-center font-size-16">
                                    Nous avons presque terminé, veuillez patienter s'il vous plait...
                                  </p>
                                )}
                                {!loading && message.text && (
                                  <p className="mt-3 font-primary text-center font-size-16">Super, nous avons terminé, nous vous redirigeons...</p>
                                )}
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-5 text-center">
                      <p className="simple-text font-primary">© {new Date().getFullYear()} Maaia. By SemeCloud</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default ShopifyOauth2;
