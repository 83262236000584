import React from "react";

function UserOutlined(props) {
  const { width, height, className } = props;
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 22H16.8C18.5673 22 20 20.5673 20 18.8V18.8C20 16.149 17.851 14 15.2 14H8.8C6.14903 14 4 16.149 4 18.8V18.8C4 20.5673 5.43269 22 7.2 22H9.5M16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default UserOutlined;
