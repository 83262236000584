import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Input, InputGroup, InputGroupText, Modal, ModalBody, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CrossOutlined from "../../assets/images/icons/CrossOutlined";
import SearchOutlined from "../../assets/images/icons/SearchOutlined";
import ArrowRightOutlined from "../../assets/images/icons/ArrowRightOutlined";

const Help = (props) => {
  // modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }
  return (
    <React.Fragment>
      <Modal isOpen={modal_list} toggle={tog_list} centered className="help-page-modal">
        <ModalBody>
          <Button
            className="close_button"
            style={{ backgroundColor: "transparent", border: "none", padding: "0px", float: "right" }}
            onClick={() => {
              tog_list();
            }}>
            <CrossOutlined height={26} />
          </Button>
          <h1 className="modal_title simple-text" style={{ marginTop: "8%", marginBottom: "6%" }}>
            login issue
          </h1>
          <Row className="gap-4 mb-5" style={{ paddingLeft: "8%", paddingRight: "8%" }}>
            <Col xl={12}>
              <Link to="/details" className="help-page-modal-button d-flex align-items-center justify-content-between" style={{ fontSize: "14px" }}>
                eget congue elit diam eget nulla? <ArrowRightOutlined style={{ color: "#FF7049" }} height={24} />
              </Link>
            </Col>
            <Col xl={12}>
              <Link to="/details" className="help-page-modal-button d-flex align-items-center justify-content-between" style={{ fontSize: "14px" }}>
                In vel nibh tellus. Fusce tincidunt elit nec tortor pharetra? <ArrowRightOutlined style={{ color: "#FF7049" }} height={24} />
              </Link>
            </Col>
            <Col xl={12}>
              <Link to="/details" className="help-page-modal-button d-flex align-items-center justify-content-between" style={{ fontSize: "14px" }}>
                eget congue elit diam eget nulla? <ArrowRightOutlined style={{ color: "#FF7049" }} height={24} />
              </Link>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content help-page">
        <div className="mx-auto col-xxl-9 col-xl-11" style={{ paddingTop: "34px" }}>
          <Breadcrumbs title="iAssistant" breadcrumbItem="Help" />
          <Card
            style={{
              borderRadius: "20px",
              background: "#563BFF",
              boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.05)",
              paddingTop: "40px",
            }}>
            <CardTitle
              className="mb-0 font-primary"
              style={{
                color: "#FFF",
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "112.5%",
              }}>
              {props.t("How can we help?")}
            </CardTitle>
            <CardBody className="p-0">
              <InputGroup
                className="mx-auto"
                style={{
                  backgroundColor: "#F9FAFB",
                  borderRadius: "13.511px",
                  marginTop: "40px",
                  marginBottom: "69px",
                  maxWidth: "625px",
                }}>
                <Input
                  className="font-primary"
                  placeholder={props.t("Recherche") + "..."}
                  style={{
                    font: "normal 400 15.2px/normal",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "#737791",
                  }}
                  // value={recherche}
                  // onChange={(e) => setRecherche(e.target.value)}
                  // onKeyPress={handleKeyPressForSearch}
                />
                <InputGroupText
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}>
                  <button className="btn" style={{ padding: "0", border: "none" }}>
                    <SearchOutlined height={26} style={{ color: "#FF7049" }} />
                  </button>
                </InputGroupText>
              </InputGroup>
            </CardBody>
          </Card>
          <Col style={{ marginTop: "60px", marginBottom: "60px" }}>
            <Row className="row-gap-4">
              <Col xl={4}>
                <Card
                  className="mb-0 help__section_card"
                  onClick={() => {
                    tog_list();
                  }}>
                  <CardBody className="d-flex align-items-center gap-4">
                    {/* <img src={error} height={62} /> */}
                    <svg width="36" height="36" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.25469 4C7.46381 5.13383 7 6.51275 7 8C7 9.0736 7.06806 10.5907 7.5 11.5L1.5 17.5C1.42035 17.5797 1.38052 17.6195 1.34847 17.6547C0.65508 18.4176 0.65508 19.5824 1.34847 20.3453C1.38052 20.3805 1.42035 20.4203 1.5 20.5V20.5C1.57965 20.5797 1.61948 20.6195 1.65474 20.6515C2.41756 21.3449 3.58244 21.3449 4.34526 20.6515C4.38052 20.6195 4.42035 20.5797 4.5 20.5L10.5 14.5C11.4093 14.9319 12.9264 15 14 15C17.866 15 21 11.866 21 8C21 7.30503 20.8987 6.63371 20.7101 6L18.2627 8.44738C17.4707 9.23941 17.0747 9.63543 16.618 9.78381C16.2163 9.91432 15.7837 9.91432 15.382 9.78381C14.9253 9.63543 14.5293 9.23941 13.7373 8.44738L13.5526 8.26274C12.7606 7.47071 12.3646 7.07469 12.2162 6.61803C12.0857 6.21635 12.0857 5.78365 12.2162 5.38197C12.3646 4.92531 12.7606 4.52929 13.5526 3.73726L16 1.28988C15.3663 1.10128 14.695 1 14 1C12.9264 1 11.9093 1.24169 11 1.67363"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2 font-primary" style={{ fontSize: "14px" }}>
                        {props.t("login issue")}
                      </div>
                      <p className="help__section_articles mb-0 font-primary" style={{ fontSize: "14px" }}>
                        3 {props.t("Articles")}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card
                  className="mb-0 help__section_card"
                  onClick={() => {
                    tog_list();
                  }}>
                  <CardBody className="d-flex align-items-center gap-4">
                    {/* <img src={payment} height={62} /> */}
                    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21 6V13.5748C21 14.8271 21 15.4532 20.8274 16.0216C20.6746 16.5247 20.4241 16.9928 20.0902 17.3991C19.713 17.858 19.192 18.2053 18.1501 18.8999L14.6626 21.2249C13.7003 21.8664 13.2192 22.1872 12.6991 22.3118C12.2395 22.422 11.7605 22.422 11.3009 22.3118C10.7808 22.1872 10.2997 21.8664 9.33744 21.2249L5.84992 18.8999C4.80796 18.2053 4.28697 17.858 3.90982 17.3991C3.57592 16.9928 3.32541 16.5247 3.1726 16.0216C3 15.4532 3 14.8271 3 13.5748V8.11937C3 6.99026 3 6.42571 3.17756 5.93283C3.33451 5.49715 3.59019 5.10373 3.92457 4.78336C4.30286 4.42093 4.81875 4.19164 5.85055 3.73307L9.40073 2.15522C10.3579 1.72982 10.8365 1.51711 11.334 1.43311C11.7749 1.35865 12.2251 1.35865 12.666 1.43311C13.1635 1.51711 13.6421 1.72982 14.5993 2.15522L16 2.77777L16.5 2.99999M9 12L11 14L15.5 9.5"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2 font-primary" style={{ fontSize: "14px" }}>
                        {props.t("payment help")}
                      </div>
                      <p className="help__section_articles mb-0 font-primary" style={{ fontSize: "14px" }}>
                        3 {props.t("Articles")}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card
                  className="mb-0 help__section_card"
                  onClick={() => {
                    tog_list();
                  }}>
                  <CardBody className="d-flex align-items-center gap-4">
                    {/* <img src={projectmanagment} height={62} /> */}
                    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22 9.5V9V9C22 7.13872 22 6.20808 21.7553 5.45492C21.2607 3.93273 20.0673 2.73931 18.5451 2.24472C17.7919 2 16.8613 2 15 2H10C7.19974 2 5.79961 2 4.73005 2.54497C3.78924 3.02433 3.02433 3.78924 2.54497 4.73005C2 5.79961 2 7.19974 2 10V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.79961 22 7.19974 22 10 22H15C16.8613 22 17.7919 22 18.5451 21.7553C20.0673 21.2607 21.2607 20.0673 21.7553 18.5451C22 17.7919 22 16.8613 22 15V15V14.5M7 10V6L6 6.79995M7 10H6M7 10L8 9.99995M6.00016 13H8L7.99984 15H6V17H8M11 17L18 17M11 12L18 12M11 7H18"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2 font-primary" style={{ fontSize: "14px" }}>
                        {props.t("plan and invoice")}
                      </div>
                      <p className="help__section_articles mb-0 font-primary" style={{ fontSize: "14px" }}>
                        3 {props.t("Articles")}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* </Row> */}
              {/* <Row style={{ marginTop: "40px" }}> */}
              <Col xl={4}>
                <Card
                  className="mb-0 help__section_card"
                  onClick={() => {
                    tog_list();
                  }}>
                  <CardBody className="d-flex align-items-center gap-4">
                    {/* <img src={compliant} height={62} /> */}
                    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14 16C14 19.3137 11.3137 22 8 22C4.68629 22 2 19.3137 2 16C2 12.6863 4.68629 10 8 10C10.087 10 11.9251 11.0655 13 12.6822M20 2L11.5 10.5M19 3L22 6L18.5 9.5L17 9.2"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2 font-primary" style={{ fontSize: "14px" }}>
                        {props.t("privacy")}
                      </div>
                      <p className="help__section_articles mb-0 font-primary" style={{ fontSize: "14px" }}>
                        3 {props.t("Articles")}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card
                  className="mb-0 help__section_card"
                  onClick={() => {
                    tog_list();
                  }}>
                  <CardBody className="d-flex align-items-center gap-4">
                    {/* <img src={affiliateMarketing} height={62} /> */}
                    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0001 19.5909C10.5719 18.7349 9.12179 17.6067 7.75744 16.2424C3.07115 11.5561 1.17165 5.85763 3.5148 3.51448C5.85794 1.17134 11.5564 3.07083 16.2427 7.75712C20.929 12.4434 22.8285 18.1419 20.4854 20.485C19.3138 21.6566 17.3034 21.7675 15.0002 20.9998M12.0001 4.40853C15.4774 2.32431 18.8244 1.8534 20.4854 3.51437C22.8285 5.85752 20.929 11.556 16.2427 16.2423C11.5564 20.9286 5.85796 22.8281 3.51482 20.4849C1.17167 18.1418 3.07117 12.4433 7.75746 7.75701C8.01866 7.49581 8.283 7.24327 8.54973 6.99964M11.9999 12H12.0099M12.4999 12C12.4999 12.2761 12.2761 12.5 11.9999 12.5C11.7238 12.5 11.4999 12.2761 11.4999 12C11.4999 11.7239 11.7238 11.5 11.9999 11.5C12.2761 11.5 12.4999 11.7239 12.4999 12Z"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2 font-primary" style={{ fontSize: "14px" }}>
                        {props.t("affiliations")}
                      </div>
                      <p className="help__section_articles mb-0 font-primary" style={{ fontSize: "14px" }}>
                        3 {props.t("Articles")}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card
                  className="mb-0 help__section_card"
                  onClick={() => {
                    tog_list();
                  }}>
                  <CardBody className="d-flex align-items-center gap-4">
                    {/* <img src={warning} height={62} /> */}
                    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 7.72594C16.2866 4.7221 15.2162 3.13469 13.8569 2.59099C12.6649 2.1142 11.3352 2.1142 10.1431 2.59099C8.50144 3.24765 7.28113 5.42679 4.84049 9.78506C2.49449 13.9743 1.3215 16.069 1.59194 17.7805C1.78851 19.0246 2.44733 20.1486 3.4367 20.9279C4.79789 22.0001 7.19861 22.0001 12 22.0001C16.8015 22.0001 19.2022 22.0001 20.5634 20.9279C21.5528 20.1486 22.2116 19.0246 22.4082 17.7805C22.6261 16.4012 21.9066 14.773 20.391 12.0001M12 13V9M12.5 16.5C12.5 16.7761 12.2761 17 12 17C11.7239 17 11.5 16.7761 11.5 16.5M12.5 16.5C12.5 16.2239 12.2761 16 12 16C11.7239 16 11.5 16.2239 11.5 16.5M12.5 16.5H11.5"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    {/* {svgs.known_issues} */}
                    <div className="d-flex flex-column" style={{ height: "fit-content" }}>
                      <div className="help__section_title mb-2 font-primary" style={{ fontSize: "14px" }}>
                        {props.t("known issues")}
                      </div>
                      <p className="help__section_articles mb-0 font-primary" style={{ fontSize: "14px" }}>
                        3 {props.t("Articles")}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(Help);
