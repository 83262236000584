import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "./rangeDatepicker.scss";
import { addDays, addMonths, addWeeks } from "date-fns";
import { DateRangePicker, DefinedRange } from "react-date-range";
import { Button } from "reactstrap";
import ArrowRightOutlined from "../../../assets/images/icons/ArrowRightOutlined";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { withTranslation } from "react-i18next";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";

const locales = {
  en: en,
  fr: fr,
  es: es,
};
function RangeDatepicker({ range, onChange, t, i18n }) {
  const [state, setState] = useState([
    {
      startDate: new Date(range.first) ?? new Date(),
      endDate: new Date(range.last) ?? addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [btn, setbtn] = useState(false);
  function formatDate(milliseconds) {
    const date = new Date(milliseconds);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés à partir de 0, donc +1
    const year = String(date.getFullYear());

    return `${day}/${month}/${year}`;
  }

  function displayRange(startDateMillis, endDateMillis) {
    const startDate = new Date(startDateMillis);
    const endDate = new Date(endDateMillis);
    const months = [
      t("Janvier"),
      t("Février"),
      t("Mars"),
      t("Avril"),
      t("Mai"),
      t("Juin"),
      t("Juillet"),
      t("Août"),
      t("Septembre"),
      t("Octobre"),
      t("Novembre"),
      t("Décembre"),
    ];

    const startDay = startDate.getDate();
    const startMonth = months[startDate.getMonth()];
    const startYear = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = months[endDate.getMonth()];
    const endYear = endDate.getFullYear();

    let displayString = "Du ";

    if (startMonth === endMonth && startYear === endYear) {
      displayString += `${startDay} au ${endDay} ${startMonth} ${startYear}`;
    } else if (startYear === endYear) {
      displayString += `${startDay} ${startMonth} au ${endDay} ${endMonth} ${startYear}`;
    } else {
      displayString += `${startDay} ${startMonth} ${startYear} au ${endDay} ${endMonth} ${endYear}`;
    }

    return displayString;
  }

  return (
    <Dropdown isOpen={btn} toggle={() => setbtn(!btn)}>
      <DropdownToggle
        tag="button"
        className="btn"
        style={{
          backgroundColor: "#FFEDBB",
          color: "black",
          fontWeight: "bold",
          minWidth: "300px",
        }}>
        {displayRange(range.first, range.last)}
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-end p-0"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}>
        <div className="rdrDateRangePickerWrapper__custom">
          <div className="d-flex">
            <DefinedRange
              onChange={(item) => {
                setState([item.selection]);
                console.log({ item });
              }}
              ranges={state}
              staticRanges={[
                {
                  label: "1 " + t("semaine"),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: addWeeks(new Date(), -1),
                    endDate: new Date(),
                  }),
                  isSelected(range) {
                    return (
                      formatDate(range.startDate) === formatDate(addWeeks(new Date(), -1)) && formatDate(range.endDate) === formatDate(new Date())
                    );
                  },
                },
                {
                  label: "1 " + t("mois"),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: addMonths(new Date(), -1),
                    endDate: new Date(),
                  }),
                  isSelected(range) {
                    return (
                      formatDate(range.startDate) === formatDate(addMonths(new Date(), -1)) && formatDate(range.endDate) === formatDate(new Date())
                    );
                  },
                },
                {
                  label: "3 " + t("mois"),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: addMonths(new Date(), -3),
                    endDate: new Date(),
                  }),
                  isSelected(range) {
                    return (
                      formatDate(range.startDate) === formatDate(addMonths(new Date(), -3)) && formatDate(range.endDate) === formatDate(new Date())
                    );
                  },
                },
              ]}
            />
            <DateRangePicker
              showSelectionPreview={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
              locale={locales[i18n.language]}
            />
          </div>

          <hr className="mx-4" />
          <div className="d-flex mx-4 mb-2 align-items-center justify-content-between">
            <div className="rdrDates d-flex">
              <div className="rdrDateFrom">
                <span>{t("From")}</span>
                {formatDate(state[0].startDate)}
              </div>
              <ArrowRightOutlined
                width={16}
                className={"mx-2"}
                style={{
                  color: "#2c3946",
                }}
              />
              <div className="rdrDateTo">
                <span>{t("To")}</span>
                {formatDate(state[0].endDate)}
              </div>
            </div>
            <div className="rdrButton_wrapper">
              <Button
                className="rdrValidate__button"
                onClick={() => {
                  onChange(state[0].startDate.getTime(), state[0].endDate.getTime());
                  setbtn(!btn);
                }}>
                {t("Valider")}
              </Button>
            </div>
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

export default withTranslation()(RangeDatepicker);
