
      import API from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!./gmail.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = htmlElement => {
              htmlElement.setAttribute('data-inboxsdk-version',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- this is injected by webpack
              ///@ts-ignore
              SDK_VERSION);
              document.head.append(htmlElement);
            };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!./gmail.css";
       export default content && content.locals ? content.locals : undefined;
