import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Row, Collapse, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import classnames from "classnames";
import getFrenchTag from "../../helpers/local_text_helper";
import { withTranslation } from "react-i18next";
import { Client, Account, Databases, ID, Query } from "appwrite";
import ChevronLeftOutlined from "../../assets/images/icons/ChevronLeftOutlined";
import ChevronRightOutlined from "../../assets/images/icons/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";
import RangeDatepicker from "../../components/Common/rangeDatepicker/RangeDatepicker";
import { addDays } from "date-fns";
import logo_maaia from "../../assets/images/maaia-logo.png";
const eventTypes = ["missing_data", "unauthorized", "user_account_error", "forbidden", "system_error"];
function AllAlerts(props) {
  const [allAlerts, setAllAlerts] = useState([]);
  const [groupedAlerts, setGroupedAlerts] = useState();
  const [expandedAlert, setExpandedAlert] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 100;
  const [loading, setLoading] = useState(true);
  const [selectedDates, setSelectedDates] = useState({
    first: Date.now() - 7 * 24 * 60 * 60 * 1000,
    last: Date.now(),
  });
  const [btn, setbtn] = useState(false);
  const [searchType, setSearchType] = useState(undefined);
  const divRef = useRef(null);
  const navigate = useNavigate();

  const onExpandedAlert = (id) => {
    setExpandedAlert(id);
  };
  // Fonction pour faire défiler vers le haut
  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const stringToColour = (str) => {
    let hash = 0;
    str.split("").forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, "0");
    }

    // Vérifiez si la couleur générée est dans la plage de jaune
    if (/^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(colour)) {
      const hexValue = colour.replace("#", "");
      const r = parseInt(hexValue.slice(0, 2), 16);
      const g = parseInt(hexValue.slice(2, 4), 16);
      const b = parseInt(hexValue.slice(4, 6), 16);

      // YELLOW
      if (r >= 200 && g >= 200 && b < 100) {
        colour = "#FF5733";
      }

      // WHITE
      const whiteThreshold = 200;
      if (r >= whiteThreshold && g >= whiteThreshold && b >= whiteThreshold) {
        colour = "#333333";
      }
    }

    return colour;
  };

  const companyId = localStorage.getItem("companyId");

  async function getAlert(page, dates, searchType) {
    setLoading(true);
    try {
      if (!companyId) {
        return;
      }
      const client = new Client();
      const databases = new Databases(client, "default");
      client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

      const cursorBefore = page < currentPage;
      const cursor = allAlerts.length > 0 ? (cursorBefore ? allAlerts[0].$id : allAlerts[allAlerts.length - 1].$id) : undefined;

      const Queries = [
        Query.equal("active", true),
        Query.equal("company", companyId),
        Query.orderDesc("$createdAt"),
        Query.limit(limit),
        Query.greaterThanEqual("$createdAt", new Date(dates ? dates.first : selectedDates.first).toISOString()),
        Query.lessThanEqual("$createdAt", addDays(new Date(dates ? dates.last : selectedDates.last), 1).toISOString()),
      ];
      if (cursor && page) {
        if (cursorBefore) {
          Queries.push(Query.cursorBefore(cursor));
        } else {
          Queries.push(Query.cursorAfter(cursor));
        }
      }
      if (searchType) {
        Queries.push(Query.equal("type", searchType));
      }
      const response = await databases.listDocuments("default", "alert", Queries);
      console.log({ alerts: response });
      setCurrentPage(page ?? 1);
      setTotalPages(Math.ceil(response.total / limit));
      setAllAlerts(response.documents);
      setGroupedAlerts(groupAlerts(response.documents));
    } catch (error) {
      console.error("Failed to fetch alert data:", error);
    }
    setLoading(false);
  }

  async function updateAlert(alert_id, group) {
    console.log("updateAlert", alert_id);
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    try {
      await databases.updateDocument("default", "alert", alert_id, { active: false });
      console.log("Alert deleted successfully");
      setGroupedAlerts({ ...groupedAlerts, [group]: groupedAlerts[group].filter((alert) => alert.$id !== alert_id) });
      console.log("updated alerts", { ...groupedAlerts, [group]: groupedAlerts[group].filter((alert) => alert.$id !== alert_id) });
    } catch (error) {
      console.log("Error updating alert:", error);
    }
  }

  const handleNotificationConsult = (alert) => {
    handleNavigate("/main-inbox", { id: alert.client_message_id });
  };
  const handleNavigate = (url, state) => {
    // Remplacez cela par la valeur que vous souhaitez passer
    navigate(url, {
      replace: true,
      state: state,
    });
  };
  // Obtient la date d'aujourd'hui à minuit
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Groupe les Alertes par période depuis la réception
  const groupAlerts = (sortedAlerts) => {
    return sortedAlerts.reduce((groups, alert) => {
      const createdAt = new Date(alert.$createdAt);
      const diffTime = today - createdAt;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Différence en jours

      let label = "";
      if (diffDays === 0) {
        label = props.t("Aujourd'hui");
      } else if (diffDays === 1) {
        label = props.t("Hier");
      } else if (diffDays <= 3) {
        label = "3 " + props.t("jours");
      } else if (diffDays <= 7) {
        label = "1 " + props.t("semaine");
      } else if (diffDays <= 14) {
        label = props.t("Plus d'une") + " " + props.t("semaine");
      } else if (diffDays <= 30) {
        label = "1 " + props.t("mois");
      } else if (diffDays <= 60) {
        label = props.t("Plus d'un") + " " + props.t("mois");
      } else if (diffDays <= 90) {
        label = "3 " + props.t("mois");
      } else {
        label = props.t("Ancien");
      }

      if (!groups[label]) {
        groups[label] = [];
      }
      groups[label].push(alert);
      return groups;
    }, {});
  };

  const handleDateChange = (date_first, date_last) => {
    console.log("entrie dates are: ", date_first, date_last);

    setSelectedDates({ first: date_first, last: date_last });
    getAlert(null, { first: date_first, last: date_last }, searchType);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAlert(1);
  }, []);
  function formatDate(dateString) {
    const options = { day: "2-digit", month: "short", year: "2-digit", hour: "2-digit", minute: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), options);
  }
  return (
    <React.Fragment>
      <div className="page-content d-flex flex-column align-items-center" style={{ height: "100vh" }}>
        <Container
          fluid
          className="px-3"
          style={{
            padding: "0",
            width: "60%",
            overflow: "auto",
          }}>
          <div ref={divRef}></div>
          <div
            className="page-title-box d-sm-flex align-items-end justify-content-between"
            style={{
              paddingLeft: "0",
              paddingRight: "0",
            }}>
            <h4 className="page-title font-primary" style={{ marginTop: "18px" }}>
              {props.t("Attentions Requises")} {allAlerts ? "(" + (allAlerts.length > 99 ? "+100" : allAlerts.length) + ")" : ""}
            </h4>
            <div
              className="d-flex gap-2"
              style={{
                position: "relative",
              }}>
              <RangeDatepicker range={selectedDates} onChange={handleDateChange} />
              <Dropdown isOpen={btn} toggle={() => setbtn(!btn)}>
                <DropdownToggle
                  tag="button"
                  className="btn p-2"
                  style={{
                    backgroundColor: "#FFEDBB",
                    color: "black",
                    fontWeight: "bold",
                  }}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13 21.0001H21M9.99999 14.9996H6.99999M21 14.9996H14M9 9.00006H21M13 2.99982H3M21 2.99982H17"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {searchType && (
                    <span
                      style={{
                        position: "absolute",
                        height: "10px",
                        width: "10px",
                        borderRadius: "10px",
                        backgroundColor: "black",
                        top: "0",
                        transform: "translate(25%, -25%)",
                      }}></span>
                  )}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end p-0">
                  <DropdownItem disabled>
                    <span>Trier :</span>
                  </DropdownItem>
                  {eventTypes.map((eventType) => (
                    <DropdownItem
                      onClick={() => {
                        if (searchType === eventType) {
                          setSearchType(undefined);
                          getAlert();
                        } else {
                          setSearchType(eventType);
                          getAlert(undefined, eventType);
                        }
                      }}
                      active={searchType === eventType}>
                      <span>{props.t(eventType)}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="mx-3 d-flex flex-column attention_requested">
            <Row className="h-100" style={{ minHeight: "500px" }}>
              <Card
                style={{
                  background: "transparent",
                  boxShadow: "none",
                  padding: "0px",
                  marginBottom: "0",
                }}>
                <CardBody
                  className="d-flex flex-column"
                  style={{
                    padding: "0",
                  }}>
                  <div
                    className="accordion accordion-flush px-0 font-primary"
                    id="accordionFlushExample"
                    style={{
                      flex: "1",
                      overflowY: "auto",
                    }}>
                    {groupedAlerts &&
                      Object.entries(groupedAlerts).map(([label, alerts], idx) => (
                        <div key={label}>
                          <div className="w-100 d-flex justify-content-center">
                            <h2
                              className="font-primary font-size-16 mb-4"
                              style={{
                                fontWeight: "600",
                                color: "grey",
                              }}>{`— ${label} —`}</h2>
                          </div>

                          {alerts.map((alert) => (
                            <div key={alert.$id} className={`accordion-item ${expandedAlert === alert.$id && "deployed"}`}>
                              <h2 className="accordion-header" id="headingFlushOne">
                                <div
                                  className={classnames("accordion-button", "fw-medium", "font-primary", {
                                    collapsed: expandedAlert !== alert.$id,
                                  })}
                                  type="button"
                                  onClick={() => {
                                    if (expandedAlert === alert.$id) {
                                      onExpandedAlert(false);
                                    } else {
                                      onExpandedAlert(alert.$id);
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}>
                                  <div className="title w-100">
                                    {alert.message}
                                    <br />
                                    <span style={{ textTransform: "capitalize", fontWeight: "300", fontSize: "12px" }}>
                                      {formatDate(alert.$createdAt)}
                                    </span>
                                  </div>
                                </div>
                              </h2>

                              <Collapse isOpen={expandedAlert === alert.$id} className="accordion-collapse">
                                <div className="accordion-body">
                                  <div className="text-muted accordion-body__text font-primary">
                                    <p>{alert.message}</p>
                                    <Col lg={4}>
                                      {alert.contact_reasons &&
                                        alert.contact_reasons.map((reason, index) => (
                                          <div
                                            key={index}
                                            className={`badge me-1 font-primary`}
                                            style={{
                                              backgroundColor: `${stringToColour(reason)}25`,
                                              color: stringToColour(reason),
                                              font: "normal 400 11px / normal Poppins, sans-serif !important",
                                              marginTop: "6px",
                                              display: "flex !important",
                                            }}>
                                            {getFrenchTag(reason)}
                                          </div>
                                        ))}
                                    </Col>
                                  </div>
                                  <div className="">
                                    <button
                                      className="action-btn font-primary"
                                      style={{
                                        backgroundColor: "black",
                                        marginRight: "20px",
                                      }}
                                      onClick={() => handleNotificationConsult(alert)}>
                                      Consulter
                                    </button>
                                    {alert.type === "missing_data" && (
                                      <button
                                        className="action-btn font-primary"
                                        style={{
                                          background: "#563BFF",
                                          marginRight: "20px",
                                        }}
                                        onClick={() => {
                                          handleNavigate("/resources", null);
                                        }}>
                                        Valider
                                      </button>
                                    )}
                                    <button
                                      className="action-btn font-primary"
                                      style={{
                                        backgroundColor: "#FF7049",
                                        boxShadow: "0px 14.34494px 36.887px 0px rgba(255, 73, 73, 0.20)",
                                      }}
                                      onClick={() => updateAlert(alert.$id, label)}>
                                      Fermer
                                    </button>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          ))}
                        </div>
                      ))}
                    {(!groupedAlerts || loading) && (
                      <div style={{ textAlign: "center", height: "100px" }}>
                        <Spinner color="primary">Loading...</Spinner>
                      </div>
                    )}
                    {!loading && allAlerts && allAlerts.length === 0 && (
                      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                        <div className="font-size-22 color-black font-primary">Aucune donnée à afficher</div>
                        <img src={logo_maaia} height={40} />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Row>
          </div>
        </Container>
        {allAlerts && allAlerts.length > 0 && (
          <div
            className="d-flex justify-content-end align-items-center gap-2 pt-2 px-3"
            style={{
              padding: "0",
              width: "60%",
            }}>
            <button
              type="button"
              style={{
                backgroundColor: "black",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={() => {
                if (currentPage > 1) {
                  getAlert(currentPage - 1, undefined, searchType);
                  scrollToTop();
                }
              }}>
              {<ChevronLeftOutlined width={24} />}
            </button>
            <span>{currentPage}</span>
            <span>{props.t("sur")}</span>
            <span>{totalPages}</span>
            <button
              type="button"
              style={{
                backgroundColor: "black",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={() => {
                if (currentPage < totalPages) {
                  getAlert(currentPage + 1, undefined, searchType);
                  scrollToTop();
                }
              }}>
              {<ChevronRightOutlined width={24} />}
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(AllAlerts);
