import React from "react";
import { Container } from "reactstrap";
//import styles from '../assets/scss/custom/pages/_agent-step-one.scss'
import styles from "../../styles/Step1.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const configAgentStepOne = () => {
  document.title = "New Page | IA - Smart Assistant";
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="New Page" breadcrumbItem="New Page" />

          <main className={styles.body}>
            <div className={styles.parent}>
              <div className={styles.header}>
                <div className={styles.step}> Step 1&nbsp;</div>
                <div className={styles.total}> / 4 </div>
                <div className={styles["roundDiv"] + " " + styles["bullet1"]}></div>
                <div className={styles.roundDiv}></div>
                <div className={styles.roundDiv}></div>
                <div className={styles.roundDiv}></div>
              </div>

              <div className={styles.container}>
                <div className={styles.left}>
                  <div className={styles.title}>Configure your live chat</div>
                  <div className={styles.wrapForm}>
                    <div className={styles.wrapInput}>
                      <input className={styles.Input} type="text" placeholder="Robert"></input>
                      <div className={styles.inputLabel}>Your name</div>
                    </div>
                    <div className={styles.text}>Colour scheme & avatar</div>
                    <div className={styles.wrapColors}>
                      <div className={styles["bigRound"] + " " + styles["color1"]}>
                        <img className={styles.check} src="ressources\icons8-check-48.png"></img>
                      </div>
                      <div className={styles["bigRound"] + " " + styles["color2"]}></div>
                      <div className={styles["bigRound"] + " " + styles["color3"]}></div>
                      <div className={styles["bigRound"] + " " + styles["color4"]}></div>
                      <div className={styles["bigRound"] + " " + styles["color5"]}></div>

                      <div className={styles["bigRound"] + " " + styles["more"]}>
                        <img className="img" src="ressources\icons8-plus-24.png" alt=""></img>
                      </div>

                      <div className={styles["bigRound"] + " " + styles["avatar"]}>
                        <img src="ressources\icons8-utilisateur-64.png"></img>
                      </div>
                    </div>
                    <div className={styles.wrapDropdown}>
                      <select className={styles.dropDown}>
                        <option value="French">French (français, langue française)</option>
                        <option value="French">French (français, langue française)</option>
                        <option value="French">French (français, langue française)</option>
                        <option value="French">French (français, langue française)</option>
                      </select>
                      <div className={styles.dropDownLabel}>Select chat language</div>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.topChat}>
                    <div className={styles.profil}>
                      <img src="https://img.icons8.com/external-becris-flat-becris/50/000000/external-user-avatars-becris-flat-becris.png" />
                    </div>
                    <div className={styles.wrapDiv}>
                      <div className={styles.aboveNom}>Discuter avec</div>
                      <div className={styles.nom}>Robert</div>
                    </div>
                    <div className={styles.topIcon}>
                      <img src="https://img.icons8.com/external-those-icons-flat-those-icons/20/000000/external-Menu-interface-those-icons-flat-those-icons-3.png" />
                    </div>
                    <div className={styles.topIcon}>
                      <img src="ressources/icons8-tri-décroissant-24.png" />
                    </div>
                  </div>
                  <div className={styles.waveDiv}></div>
                  <div className={styles.messages}>
                    <div className={styles["singleMsg"] + " " + styles["sortant"]}>je recherche ce modèle depuis une éternité !</div>
                    <div className={styles["singleMsg"] + " " + styles["entrant"]}>
                      Laissez-moi vérifier ça très vite ! On vient de recevoir une grosse livraison ce matin. Tous les produits seront en stock demain
                      mais je peux vous en envoyer une paire aujoud'hui ! &#128293;
                    </div>
                    <div className={styles["singleMsg"] + " " + styles["sortant"]}>C'est génial ! &#128516;</div>
                    <div className={styles["singleMsg"] + " " + styles["sortant"]}>Merci beaucoup !</div>
                    <hr className={styles.hr}></hr>

                    <div className={styles.input}>Saisissez votre message...</div>
                    <div className={styles.iconsWrap}>
                      <img className={styles.bottomIcon} src="ressources\icons8-trombone-24.png"></img>

                      <img className={styles.bottomIcon} src="ressources\icons8-lol-24.png"></img>

                      <div className={styles.textCredit}>POWERED BY</div>
                      <img className={styles["bottomIcon"] + " " + styles["logoTidio"]} src="ressources\icons8-bavarder-48.png"></img>

                      <div className={styles.tidio}>TIDIO</div>
                    </div>
                    <div className={styles.sendButton}>
                      <img src="ressources\icons8-envoyé-30.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.footer}>
                <button className={styles.button}>Continue</button>
              </div>
            </div>
          </main>
        </Container>
      </div>
    </>
  );
};

export default configAgentStepOne;
