const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
};

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
};

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
};

const leftSideBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
};

export { layoutTypes, layoutWidthTypes, topBarThemeTypes, leftSidebarTypes, leftSideBarThemeTypes };
