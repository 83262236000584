import React from "react";

function EditFilled({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.32"
        d="M13.4242 5.51489L5.67934 13.2596C5.17003 13.7689 4.91538 14.0236 4.71303 14.3137C4.53343 14.5712 4.38477 14.849 4.27013 15.1413C4.14096 15.4706 4.07034 15.8237 3.92908 16.53L3.27928 19.779C3.2006 20.1724 3.16125 20.3691 3.2193 20.5095C3.27005 20.6321 3.36751 20.7296 3.49017 20.7803C3.63048 20.8384 3.8272 20.799 4.22064 20.7203L7.46964 20.0705C8.1759 19.9293 8.52904 19.8587 8.85833 19.7295C9.15061 19.6149 9.42838 19.4662 9.6859 19.2866C9.97603 19.0843 10.2307 18.8296 10.74 18.3203L18.4844 10.5759C18.4058 10.559 18.3288 10.5405 18.253 10.5202C15.9237 9.89607 14.1042 8.07663 13.4801 5.74726C13.4597 5.67119 13.4411 5.59387 13.4242 5.51489Z"
        fill="currentColor"
      />
      <path
        d="M14.7754 4.16381L15.7375 3.20172C16.5295 2.40968 16.9256 2.01366 17.3822 1.86529C17.7839 1.73478 18.2166 1.73478 18.6183 1.8653C19.075 2.01369 19.471 2.40972 20.263 3.20177L20.7981 3.7369C21.5901 4.52893 21.9861 4.92495 22.1345 5.38161C22.265 5.78329 22.265 6.21598 22.1345 6.61766C21.9861 7.07431 21.5901 7.47032 20.7981 8.26234L19.8354 9.22496C19.3207 9.20166 18.9609 9.15696 18.6414 9.07135C16.8296 8.58589 15.4145 7.17077 14.9291 5.35904C14.8434 5.03929 14.7987 4.67922 14.7754 4.16381Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EditFilled;
