import {
  FETCH_MAILS_REDUX,
  FETCH_SINGLE_MAIL_REDUX,
  SEARCH_MAILS_REDUX,
  FETCH_FOLDERS_REDUX,
  APPLY_MAILS_SUCCESSFULL,
  APPLY_MAILS_ERROR,
  APPLY_FOLDERS_SUCCESSFULL,
  APPLY_FOLDERS_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  fetchError: null,
  folders_loading: false,
  folders_fetchError: null,
  currentPage: 1,
};

const mails_redux = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAILS_REDUX:
      state = {
        ...state,
        loading: true,
        fetchError: null,
      };
      break;
    case FETCH_SINGLE_MAIL_REDUX:
      state = {
        ...state,
        loading: true,
        fetchError: null,
      };
      break;
    case SEARCH_MAILS_REDUX:
      state = {
        ...state,
        loading: true,
        fetchError: null,
      };
      break;
    case APPLY_MAILS_SUCCESSFULL:
      state = {
        ...state,
        loading: false,
        response: action.payload,
        fetchError: null,
        currentPage: action.payload.currentPage,
      };
      break;
    case APPLY_MAILS_ERROR:
      state = {
        ...state,
        loading: false,
        fetchError: action.payload.message,
        currentPage: action.payload.currentPage,
      };
      break;
    case FETCH_FOLDERS_REDUX:
      state = {
        ...state,
        folders_loading: true,
        folders_fetchError: null,
      };
      break;
    case APPLY_FOLDERS_SUCCESSFULL:
      state = {
        ...state,
        folders_response: action.payload,
        folders_loading: false,
        folders_fetchError: null,
      };
      break;
    case APPLY_FOLDERS_ERROR:
      state = {
        ...state,
        folders_loading: false,
        folders_fetchError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

// const folders_redux = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_FOLDERS_REDUX:
//       state = {
//         ...state,
//         loading: true,
//         fetchError: null,
//       }
//       break
//     case APPLY_FOLDERS_SUCCESSFULL:
//       state = {
//         ...state,
//         loading: false,
//         response: action.payload,
//         fetchError: null,
//       }
//       break
//     case APPLY_FOLDERS_ERROR:
//       state = {
//         ...state,
//         loading: false,
//         fetchError: action.payload,
//       }
//       break
//     default:
//       state = { ...state }
//       break
//   }
//   return state
// }

export default mails_redux;
