import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { Client, Account, Databases, Query } from "appwrite";
import { Link, useNavigate } from "react-router-dom";
import getFrenchTag from "../../helpers/local_text_helper";
import Flatpickr from "react-flatpickr";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Line } from "react-chartjs-2";
import logo_maaia from "../../assets/images/maaia-logo.png";
import clients_satisfaction from "../../assets/images/clients_satisfaction.png";
import { withTranslation } from "react-i18next";

const option = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    dataset: [
      {
        display: false,
        gridLines: {
          drawBorder: false,
          offset: true,
        },
        ticks: {
          fontColor: "#686868",
        },
      },
    ],
    dataset1: [
      {
        barPercentage: 0.7,
        categoryPercentage: 0.5,

        ticks: {
          fontColor: "#7b919e",
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    ],
  },
};

const SentimentChart = (props) => {
  // let data = null;

  const navigate = useNavigate();
  const processStateMap = new Map();
  const dataMap = new Map();
  const [formattedDataList, setformattedDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allMessages, setallMessages] = useState();
  const [treatedMessages, settreatedMessages] = useState();
  const [untreatedMessages, setuntreatedMessages] = useState();
  const [selectedDates, setSelectedDates] = useState([Date.now() - 7 * 24 * 60 * 60 * 1000, Date.now()]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  function convertDate(milliseconds) {
    const date = new Date(milliseconds);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const dateFormated = `${day}/${month}/${year}`;
    return dateFormated;
  }
  const theMessages = props.messages;

  // console.log("theMessages is", theMessages);

  const handleDateChange = (dates) => {
    if (dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];

      const timeDifference = endDate.getTime() - startDate.getTime();

      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor(timeDifference / millisecondsInADay);

      console.log("Durée en jours:", daysDifference);
      if (daysDifference > 12) {
        console.log("On affiche en mois");
      } else if (daysDifference > 30) {
        console.log("On affiche en Années");
      }
    }
  };
  // const getMessageData = (theData) => {
  //     var data = theData;
  //     data.forEach((message) => {
  //         // const the_date = message.message_date;
  //         const the_date = convertDate(message.message_date);
  //         const processState = message.process_state;
  //         const authState = message.auth_state;

  //         if (dataMap.has(the_date)) {
  //             dataMap.set(the_date, {
  //                 sentiments_happy_total: dataMap.get(the_date).sentiments_happy_total + (message.client_sentiment === "happy" ? 1 : 0),
  //                 sentiments_sad_total: dataMap.get(the_date).sentiments_sad_total + (message.client_sentiment === "sad" ? 1 : 0),
  //                 sentiments_neutral_total: dataMap.get(the_date).sentiments_neutral_total + (message.client_sentiment === "neutral" ? 1 : 0),
  //             });
  //         } else {
  //             dataMap.set(the_date, {
  //                 sentiments_happy_total: message.client_sentiment === "happy" ? 1 : 0,
  //                 sentiments_sad_total: message.client_sentiment === "sad" ? 1 : 0,
  //                 sentiments_neutral_total: message.client_sentiment === "neutral" ? 1 : 0,
  //             });
  //         }
  //     });
  //     const formattedDataListLocal = [];

  //     dataMap.forEach((values, timestamp) => {
  //         const { sentiments_happy_total, sentiments_sad_total, sentiments_neutral_total } = values;
  //         formattedDataListLocal.push([(timestamp), [sentiments_happy_total, sentiments_sad_total, sentiments_neutral_total]]);
  //     });

  //     setformattedDataList(formattedDataListLocal);
  //     console.log('Formatted', formattedDataListLocal);

  //     setLoading(false);

  // }

  function generateDateList() {
    const dateList = [];
    const today = new Date();

    for (let i = 0; i < 8; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() - i);

      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Les mois commencent à 0 dans JavaScript, donc nous ajoutons 1
      const year = currentDate.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      dateList.push(formattedDate);
    }

    return dateList;
  }

  function generateTimestampList() {
    const timestampList = [];
    const today = new Date();

    for (let i = 0; i < 8; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() - i);

      const timestamp = currentDate.getTime(); // Obtient le timestamp en millisecondes

      timestampList.push(timestamp);
    }

    return timestampList;
  }

  const getMessageData = (theData) => {
    if (!theData || theData.length === 0) {
      // theData = generateTimestampList()
      //   .sort((a, b) => b < a)
      //   .map((element) => {
      //     return { message_date: element, client_sentiment: "-" };
      //   });
    }
    theData.forEach((message) => {
      console.log("message.message_date is", message.message_date);
      const the_date = convertDate(message.message_date);
      /*
      // Split la date en jour, mois et année
      const dateParts = the_date.split("/");
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Soustrayez 1 car les mois en JavaScript commencent à partir de 0
      const year = parseInt(dateParts[2], 10);

      // Créez un objet Date valide
      const dateObj = new Date(year, month, day);

      // Obtenir le jour de la semaine (0 pour dimanche, 1 pour lundi, 2 pour mardi, etc.)
      const dayOfWeekIndex = dateObj.getDay();

      // Obtenir le nom du jour de la semaine à partir de l'index
      const daysOfWeek = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      const dayOfWeek = daysOfWeek[dayOfWeekIndex];

      */
      if (dataMap.has(the_date)) {
        dataMap.set(the_date, {
          sentiments_happy_total: dataMap.get(the_date).sentiments_happy_total + (message.client_sentiment === "happy" ? 1 : 0),
          sentiments_sad_total: dataMap.get(the_date).sentiments_sad_total + (message.client_sentiment === "sad" ? 1 : 0),
          sentiments_neutral_total: dataMap.get(the_date).sentiments_neutral_total + (message.client_sentiment === "neutral" ? 1 : 0),
        });
      } else {
        dataMap.set(the_date, {
          sentiments_happy_total: message.client_sentiment === "happy" ? 1 : 0,
          sentiments_sad_total: message.client_sentiment === "sad" ? 1 : 0,
          sentiments_neutral_total: message.client_sentiment === "neutral" ? 1 : 0,
        });
      }
    });
    console.log("dataMap is", dataMap);

    /*
    const formattedDataListLocal = [];

    // Parcourez les jours de la semaine dans l'ordre
    const daysOfWeek = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    daysOfWeek.forEach((dayOfWeek) => {
      const values = dataMap.get(dayOfWeek) || {
        sentiments_happy_total: 0,
        sentiments_sad_total: 0,
        sentiments_neutral_total: 0,
      };
      formattedDataListLocal.push([
        dayOfWeek,
        [
          values.sentiments_happy_total,
          values.sentiments_sad_total,
          values.sentiments_neutral_total,
        ],
      ]);
    });
    */

    console.log("dataMap.keys() is", Array.from(dataMap.keys()));
    console.log("dataMap.values() is", Array.from(dataMap.values()));

    /*
    GRAPHIQUE EN BATONNETS
    const dataForGraphic = Array.from(dataMap.keys()).map(element => {
      console.log("element is", element);
      console.log("dataMap is", dataMap);
      console.log("dataMap[element] is", dataMap.get(element));
      return {
        name: element,
        Mécontent: dataMap.get(element).sentiments_sad_total,
        Satisfait: dataMap.get(element).sentiments_happy_total,
        Neutre: dataMap.get(element).sentiments_neutral_total,
      };
    },);
    */

    const data = {
      labels: Array.from(dataMap.keys()),
      datasets: [
        {
          label: "Content",
          lineTension: 0.2,
          borderColor: ["#329993"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#329993",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).sentiments_happy_total),
        },
        {
          label: "Mécontent",
          lineTension: 0.2,
          backgroundColor: "rgba(235, 239, 242, 0)",
          borderColor: ["#ff7049"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#ff7049",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).sentiments_sad_total),
        },
        {
          label: "Neutre",
          lineTension: 0.2,
          backgroundColor: "rgba(235, 239, 242, 0)",
          borderColor: ["grey"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "grey",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).sentiments_neutral_total),
        },
      ],
    };

    console.log("dataForGraphic is", data);
    setformattedDataList(data);
    // console.log("formattedDataListLocal is", formattedDataListLocal);

    setLoading(false);
  };
  // if (loading) {
  //   data = {
  //     labels: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //     ],
  //     datasets: [
  //       {
  //         label: "Apple",
  //         data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
  //         backgroundColor: "#fdd667",
  //         barThickness: 10,
  //       },
  //       {
  //         label: "New",
  //         data: [46, 37, 59, 54, 62, 58, 64, 60, 66],
  //         backgroundColor: "#ffedbb",
  //         barThickness: 10,
  //       },
  //       {
  //         label: "Samsung",
  //         data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
  //         backgroundColor: "#563bff",
  //         barThickness: 10,
  //       },
  //       {
  //         label: "Oppo",
  //         data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
  //         backgroundColor: "#fcb800",
  //         barThickness: 10,
  //       },
  //     ],
  //   };
  // } else {
  //   // if (theMessages) {
  //   data = {
  //     labels: formattedDataList.map((item) => item[0]),
  //     datasets: [
  //       {
  //         label: "SATISFAIT",
  //         data: formattedDataList.map((item) => item[1][0]),
  //         backgroundColor: "#FD9277",
  //         barThickness: 10,
  //       },
  //       {
  //         label: "INSATISFAIT",
  //         data: formattedDataList.map((item) => item[1][1]),
  //         backgroundColor: "#FFA412",
  //         barThickness: 10,
  //       },
  //       {
  //         label: "NEUTRE",
  //         data: formattedDataList.map((item) => item[1][2]),
  //         backgroundColor: "#563BFF",
  //         barThickness: 10,
  //       },
  //     ],
  //   };
  // }

  var option = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            // max: 200,
            // min: 0,
            stepSize: 50,
            // zeroLineColor: "#7b919e",
            // borderDash: [3, 3],
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };
  useEffect(() => {
    getMessageData(theMessages);
  }, [theMessages]);
  return (
    <div
      className="font-primary"
      style={{
        position: "relative",
        display: "grid",
        gridTemplateRows: "minmax(0,3fr)",
        height: formattedDataList && formattedDataList.labels?.length > 0 ? "86%" : "100%",
      }}>
      {formattedDataList && formattedDataList.labels?.length > 0 ? (
        <Line data={formattedDataList} options={option} />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + clients_satisfaction + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "119%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          <div className="font-primary font-size-22 color-black mt-5">{props.t("En attente de données")}</div>
          <img src={logo_maaia} height={40} />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SentimentChart);
