import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Client, Account, Databases, Query } from "appwrite";
import { withTranslation } from "react-i18next";
const ComparisonLineColumn = (props) => {
  const dataMap = new Map();
  let ComparisonLineColumnData = null;
  const [formattedDataList, setformattedDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allMessages, setallMessages] = useState();
  const [treatedMessages, settreatedMessages] = useState();
  const [untreatedMessages, setuntreatedMessages] = useState();
  const processStateMap = new Map();

  function convertDate(milliseconds) {
    const date = new Date(milliseconds);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const dateFormated = `${day}/${month}/${year}`;
    console.log("dateFormated", dateFormated);
    return dateFormated;
  }
  async function getMessageData(date) {
    console.log("==============getMessageData", date);
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    const allMessages = [];
    const treatedMessages = [];
    const untreatedMessages = [];

    return databases
      .listDocuments(
        "default",
        "message"
        // [
        //     Query.greaterThanEqual("message_date", Date.parse(date[0])),
        //     Query.lessThanEqual("message_date", Date.parse(date[1])),
        // ]
      )
      .then(
        function (response) {
          var data = response.documents;
          console.log("Successfully fetched message data", data[0]);
          if (data.length === 0) {
            processStateMap.set("other", 1);
          } else {
            data.forEach((message) => {
              // const the_date = message.message_date;
              const the_date = convertDate(message.message_date);
              const processState = message.process_state;
              const authState = message.auth_state;
              if (dataMap.has(the_date)) {
                dataMap.set(the_date, {
                  message_total: dataMap.get(the_date).message_total + 1,
                  auth_total: dataMap.get(the_date).auth_total + (message.auth_state === "authorized" ? 1 : 0),
                  treated_total: dataMap.get(the_date).treated_total + (message.process_state === "Treated" ? 1 : 0),
                  untreated_total: dataMap.get(the_date).untreated_total + (message.process_state === "Untreated" ? 1 : 0),
                });
              } else {
                dataMap.set(the_date, {
                  message_total: 1,
                  auth_total: message.auth_state === "authorized" ? 1 : 0,
                  treated_total: message.process_state === "Treated" ? 1 : 0,
                  untreated_total: message.process_state === "Untreated" ? 1 : 0,
                });
              }
            });
            console.log("////dataMap/////////", dataMap);
            const formattedDataList = [];

            dataMap.forEach((values, timestamp) => {
              const { message_total, auth_total, treated_total, untreated_total } = values;
              formattedDataList.push([timestamp, [message_total, auth_total, treated_total, untreated_total]]);
            });
            setformattedDataList(formattedDataList);
            console.log("formattedDataList", formattedDataList);
            /*
                data.forEach((message) => {
                    // dataMap[message.message_date] = {totalMessage}
                    const processState = message.process_state;
                    console.log('processState', processState);
                    allMessages.push(message);  
                    if (processState === "Treated") {
                        treatedMessages.push(message);  
                    } else if (processState === "Untreated") {
                        untreatedMessages.push(message);  
                    }
                });*/
          }
          setLoading(false);
          setallMessages(allMessages);
          settreatedMessages(treatedMessages);
          setuntreatedMessages(untreatedMessages);
          console.log("All Messages:", allMessages);
          console.log("Treated Messages:", treatedMessages);
          console.log("Untreated Messages:", untreatedMessages);
        },
        function (error) {
          console.log(error); // Echec
          console.log("Failed to fetch message data:", error);
          setLoading(false);
        }
      );
  }
  if (loading) {
    ComparisonLineColumnData = {
      series: [
        {
          name: "TRAITÉ",
          type: "area",
          data: [44, 55, 41, 42, 22, 43, 21, 41, 56, 27, 43, 27],
        },
        {
          name: "Profit",
          type: "line",
          data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
        },
      ],
      options: {
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 0.5, 1],
          curve: "smooth",
          dashArray: [0, 8, 5],
        },
        plotOptions: {
          bar: {
            columnWidth: "18%",
          },
        },
        colors: ["#85b10b", "rgb(251, 77, 83)"],

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        markers: {
          size: 0,
        },
        legend: {
          offsetY: 11,
        },
        xaxis: {
          type: "month",
        },
        yaxis: {
          title: {
            text: "Points",
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  } else {
    ComparisonLineColumnData = {
      series: [
        {
          name: "Total des mails",
          type: "area",
          data: formattedDataList.map((item) => item[1][0]),
        },
        {
          name: "Mails traités",
          type: "area",
          data: formattedDataList.map((item) => item[1][2]),
        },
        {
          name: "Total des mails autorisés",
          type: "line",
          data: formattedDataList.map((item) => item[1][1]),
        },
      ],
      options: {
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 0.5, 1],
          curve: "smooth",
          dashArray: [0, 8, 5],
        },
        plotOptions: {
          bar: {
            columnWidth: "18%",
          },
        },
        colors: ["#85b10b", "rgb(251, 77, 83)", "#fcb92c"],

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: formattedDataList.map((item) => item[0]),
        markers: {
          size: 0,
        },
        legend: {
          offsetY: 11,
        },
        xaxis: {
          type: "month",
        },
        yaxis: {
          title: {
            text: "Points",
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  }
  useEffect(() => {
    getMessageData([new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()]);
  }, []);

  return (
    <React.Fragment>
      <ReactApexChart
        options={ComparisonLineColumnData.options}
        series={ComparisonLineColumnData.series}
        type="line"
        height="350"
        stacked="false"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default withTranslation()(ComparisonLineColumn);
