import React from "react";

const HelpFilled = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.32"
        d="M2.24087 16.5125C2.60252 17.2931 3.03665 18.1104 2.78372 18.9906C2.60548 19.6108 2.216 20.1186 2.16118 20.7861C2.06787 21.9222 3.06842 22.8429 4.1929 22.6555C5.24137 22.4808 6.24918 21.4847 7.35382 21.7445C7.46428 21.7705 7.83036 21.8998 8.72366 22.2194C9.72211 22.5765 10.786 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 13.6102 1.60482 15.1395 2.24087 16.5125Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 8.75C11.3096 8.75 10.75 9.30964 10.75 10V10.5C10.75 10.9142 10.4142 11.25 10 11.25C9.58579 11.25 9.25 10.9142 9.25 10.5V10C9.25 8.48122 10.4812 7.25 12 7.25C13.5188 7.25 14.75 8.48122 14.75 10V10.1213C14.75 10.8828 14.4475 11.6132 13.909 12.1517L12.5303 13.5303C12.2374 13.8232 11.7626 13.8232 11.4697 13.5303C11.1768 13.2374 11.1768 12.7626 11.4697 12.4697L12.8483 11.091C13.1055 10.8338 13.25 10.485 13.25 10.1213V10C13.25 9.30964 12.6904 8.75 12 8.75ZM10.75 16C10.75 15.3096 11.3096 14.75 12 14.75C12.6904 14.75 13.25 15.3096 13.25 16C13.25 16.6904 12.6904 17.25 12 17.25C11.3096 17.25 10.75 16.6904 10.75 16Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HelpFilled;
