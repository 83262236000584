import React from "react";

function HomeFilled(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2519_24316)">
        <path
          opacity="0.32"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.9971 3.83672C15.8568 2.12447 14.7866 1.26835 13.5998 0.94002C12.5529 0.650409 11.4471 0.650409 10.4002 0.94002C9.21339 1.26835 8.14324 2.12447 6.00293 3.83672L4.85293 4.75672C3.52983 5.8152 2.86828 6.34444 2.39209 7.00134C1.97024 7.58329 1.65638 8.23631 1.46548 8.92927C1.25 9.71147 1.25 10.5587 1.25 12.2531V13.1495C1.25 16.5098 1.25 18.19 1.90396 19.4735C2.4792 20.6024 3.39708 21.5203 4.52606 22.0956C5.80953 22.7495 7.48969 22.7495 10.85 22.7495H13.15C16.5103 22.7495 18.1905 22.7495 19.4739 22.0956C20.6029 21.5203 21.5208 20.6024 22.096 19.4735C22.75 18.19 22.75 16.5098 22.75 13.1495V12.2531C22.75 10.5587 22.75 9.71147 22.5345 8.92927C22.3436 8.23631 22.0298 7.58329 21.6079 7.00134C21.1317 6.34444 20.4702 5.8152 19.1471 4.75672L17.9971 3.83672Z"
          fill="currentColor"
        />
        <path
          d="M15 19.4V14C15 13.0681 15 12.6022 14.8478 12.2346C14.6448 11.7446 14.2554 11.3552 13.7654 11.1522C13.3978 11 12.9319 11 12 11C11.0681 11 10.6022 11 10.2346 11.1522C9.74458 11.3552 9.35523 11.7446 9.15224 12.2346C9 12.6022 9 13.0681 9 14V19.4C9 19.9601 9 20.2401 9.10899 20.454C9.20487 20.6422 9.35785 20.7951 9.54601 20.891C9.75992 21 10.0399 21 10.6 21H13.4C13.9601 21 14.2401 21 14.454 20.891C14.6422 20.7951 14.7951 20.6422 14.891 20.454C15 20.2401 15 19.9601 15 19.4Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2519_24316">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HomeFilled;
