import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../components/Common/withRouter";
import NoDesktopTempUI from "../Pages/Utility/NoDesktopTempUI";

const NonAuthLayout = (props) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);
  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 1200);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);
  return isLargeScreen ? <React.Fragment>{props.children}</React.Fragment> : <NoDesktopTempUI />;
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
