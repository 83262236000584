import React from "react";
import ListTables from "./list";
const Resources = () => {
  return (
    <React.Fragment>
      <div className="page-content ressources-content" style={{ paddingLeft: "0" }}>
        <ListTables />
      </div>
    </React.Fragment>
  );
};

export default Resources;
