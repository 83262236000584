import React, { useState } from "react";
import InboxSidebar from "./Sidebar";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Form, Card, CardBody, Col, Row, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const EmailCompose = () => {
  document.title = "Email Compose  | IA - Smart Assistant";

  const [folderbtn, setfolderbtn] = useState(false);
  const [tagbtn, settagbtn] = useState(false);
  const [menubtn, setmenubtn] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Email" breadcrumbItem="Email Compose" />
          <Row className="mb-4">
            {/* SideBar */}
            <InboxSidebar />
            <Col xl={9}>
              <Card className="mb-0">
                <CardBody>
                  <div className="mb-0">
                    <div className="mb-3">
                      <input type="email" className="form-control" placeholder="To" />
                    </div>

                    <div className="mb-3">
                      <input type="text" className="form-control" placeholder="Subject" />
                    </div>
                    <div id="email-editor" style={{ minHeight: "360px" }}>
                      <Form method="post">
                        <Editor
                          className="mb-3 ql-size-large"
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Start From Here..."
                        />
                      </Form>
                    </div>
                    <div className="btn-toolbar">
                      <div className="">
                        <button type="button" className="btn btn-primary waves-effect waves-light me-1">
                          <i className="far fa-save"></i>
                        </button>
                        <button type="button" className="btn btn-primary waves-effect waves-light me-1">
                          <i className="far fa-trash-alt"></i>
                        </button>
                        <button className="btn btn-info waves-effect waves-light me-1">
                          <i className="fab fa-telegram-plane ms-2"></i>
                          <span> Générer une réponse</span>{" "}
                        </button>
                        <button className="btn btn-info waves-effect waves-light">
                          {" "}
                          <span>Send</span> <i className="fab fa-telegram-plane ms-2"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailCompose;
