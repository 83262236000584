import React, { useState } from "react";

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import checkImgSrc from "../../assets/images/sucesscheck.png";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const RecoverPassword = () => {
  document.title = "Recover Password | IA - Smart Assistant";

  const [message, setMessage] = useState({ type: null, alert: null });
  const [loading, setLoading] = useState(null);

  return (
    <React.Fragment>
      {/* <div className="bg-pattern" style={{ height: "100vh" }}>
                <div className="bg-overlay"></div>
                <div className="account-pages pt-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} md={12} xl={12}>
                                <Card className='mt-5'>
                                    <CardBody className="p-4">
                                        <div className="">
                                            <div className="text-center">
                                                <Link to="/" className="">
                                                    <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                                                    <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                                                </Link>
                                            </div>
                                            <h4 className="font-size-18 text-muted mt-2 text-center">Reset Password</h4>
                                            <p className="mb-5 text-center">Reset your Password with iAssistant.</p>
                                            <form className="form-horizontal" action="#">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="alert alert-warning alert-dismissible">
                                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                            Enter your <b>Email</b> and instructions will be sent to you!
                                                        </div>

                                                        <div className="mt-4">
                                                            <label className="form-label" htmlFor="useremail">Email</label>
                                                            <input type="email" className=".form-control-recoverpw" id="useremail" placeholder="Enter email" />
                                                        </div>
                                                        <div className="d-grid mt-4">
                                                            <button className="btn btn-primary waves-effect waves-light" type="submit">Send Email</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p className="text-white-50">Don't have an account ?  <Link to="/auth-register" className="fw-medium text-primary"> Register  </Link> </p>
                                    <p className="text-white-50">© {new Date().getFullYear()} iAssistant. By SemeCloud</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div> */}
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="account-pages pt-5">
          <Container>
            <Row className="w-100 justify-content-center">
              <Col lg={12} md={6} xl={6}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <div
                            className=""
                            style={{
                              marginTop: "3vh",
                              color: "#151d48",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "54px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "140%",
                            }}>
                            FINTINOS
                          </div>
                        </Link>
                      </div>
                      <h4 className="font-size-18 text-muted mt-2 text-center">Réinitialisez votre mot de passe</h4>
                      <form className="form-horizontal" action="#">
                        <Row>
                          <Col md={12}>
                            {!loading && message.type === "succes" && (
                              <div className="alert alert-success text-center">Félicitations ! Votre compte est maintenant vérifé.</div>
                            )}
                            {!loading && message.type === "error" && <div className="alert alert-danger text-center">{message.alert}</div>}
                          </Col>
                          {message.type && (
                            <Col className="d-flex justify-content-center">
                              <img style={{ display: loading || message.type === "error" ? "none" : "block" }} src={checkImgSrc} alt="" />
                              {loading && <span className="spinner-border spinner-border-xxl me-2 mt-4" role="status" aria-hidden="true"></span>}
                            </Col>
                          )}
                          <Row>
                            <Col md={12}>
                              {!message.type && (
                                <div className="alert alert-warning alert-dismissible">
                                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                  Entrez votre <b>Email</b> et les instructions vous seront envoyées !
                                </div>
                              )}
                              <div className="mt-4">
                                <label className="form-label" htmlFor="useremail">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control form-control-recoverpw"
                                  id="useremail"
                                  placeholder="Entrez votre adresse mail"
                                />
                              </div>
                              <div className="d-grid mt-4">
                                <button
                                  className="btn btn-primary waves-effect waves-light"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    // backgroundColor: '#FF7049',
                                    border: "none",
                                    // color: '#FFF',
                                    borderRadius: "10px",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17.005px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "29.152px",
                                    // boxShadow: '0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.20)',
                                  }}>
                                  {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />}{" "}
                                  {"Envoyer l'email"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="simple-text font-primary">
                    Vous n'avez pas de compte ?{" "}
                    <Link to="/register" className="fw-medium text-primary simple-text">
                      {" "}
                      Inscrivez-vous{" "}
                    </Link>{" "}
                  </p>
                  <p className="simple-text font-primary">© {new Date().getFullYear()} iAssistant. By SemeCloud</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecoverPassword;
