import React from "react";

function LockOutlined(props) {
  const { width, height, className } = props;
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 8H14.5H14.6C16.8402 8 17.9603 8 18.816 8.43597C19.5686 8.81947 20.1805 9.43139 20.564 10.184C21 11.0397 21 12.1598 21 14.4V15.6C21 17.8402 21 18.9603 20.564 19.816C20.1805 20.5686 19.5686 21.1805 18.816 21.564C17.9603 22 16.8402 22 14.6 22H9.4C7.15979 22 6.03968 22 5.18404 21.564C4.43139 21.1805 3.81947 20.5686 3.43597 19.816C3 18.9603 3 17.8402 3 15.6V14.4C3 12.1598 3 11.0397 3.43597 10.184C3.81947 9.43139 4.43139 8.81947 5.18404 8.43597C6.03968 8 7.15979 8 9.4 8H9.5H10M7 8V6.8C7 5.11984 7 4.27976 7.32698 3.63803C7.6146 3.07354 8.07354 2.6146 8.63803 2.32698C9.27976 2 10.1198 2 11.8 2H12.2C13.8802 2 14.7202 2 15.362 2.32698C15.9265 2.6146 16.3854 3.07354 16.673 3.63803C17 4.27976 17 5.11984 17 6.8V8M12 14V16"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default LockOutlined;
