import React from "react";

function RessourcesFilled() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25 19.5V7.65C3.25 5.40979 3.25 4.28969 3.68597 3.43404C4.06947 2.68139 4.68139 2.06947 5.43404 1.68597C6.28969 1.25 7.40979 1.25 9.65 1.25H14.35C16.5902 1.25 17.7103 1.25 18.566 1.68597C19.3186 2.06947 19.9305 2.68139 20.314 3.43404C20.75 4.28969 20.75 5.40979 20.75 7.65V17H19.25H5.75C4.36929 17 3.25 18.1193 3.25 19.5Z"
        fill="currentColor"
      />
      <path
        d="M6.5 16.25C4.70507 16.25 3.25 17.7051 3.25 19.5C3.25 21.2949 4.70507 22.75 6.5 22.75H14.9167C16.6241 22.75 17.4778 22.75 18.159 22.4936C19.2371 22.0879 20.0879 21.2371 20.4936 20.159C20.75 19.4778 20.75 18.6241 20.75 16.9167V16.25H6.5Z"
        fill="currentColor"
      />
      <path
        d="M15.5 7C15.5 7.41421 15.1642 7.75 14.75 7.75H9.25C8.83579 7.75 8.5 7.41421 8.5 7C8.5 6.58579 8.83579 6.25 9.25 6.25H14.75C15.1642 6.25 15.5 6.58579 15.5 7Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default RessourcesFilled;
