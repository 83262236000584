import React, { useEffect } from "react";
// Si vous utilisez React Router

import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ShopifyConfiguration from "./shopifyConfiguration";
import WordPressConfiguration from "./wordPressConfiguration";
import WebConfiguration from "./webConfiguration";
const Configuration = () => {
  let componentToRender;
  const searchParams = new URLSearchParams(window.location.search);

  switch (searchParams.get("testvalue")) {
    case "Shopify":
      componentToRender = <ShopifyConfiguration />;
      break;
    case "WordPress":
      componentToRender = <WordPressConfiguration />;
      break;
    case "Website":
      componentToRender = <WebConfiguration />;
      break;
    default:
      componentToRender = <div>No matching component</div>;
      break;
  }
  useEffect(() => {}, []);
  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="iAssistant" breadcrumbItem="Configuration" />
        {componentToRender}
      </Container>
    </div>
  );
};

export default Configuration;
