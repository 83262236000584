import React from "react";

function CheckCircleMono({ width, height, style, className }) {
  return (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.32"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0303 8.96967C17.3232 9.26256 17.3232 9.73744 17.0303 10.0303L11.5303 15.5303C11.3897 15.671 11.1989 15.75 11 15.75C10.8011 15.75 10.6103 15.671 10.4697 15.5303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L11 13.9393L15.9697 8.96967C16.2626 8.67678 16.7374 8.67678 17.0303 8.96967Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CheckCircleMono;
