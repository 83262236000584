import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Client, Account, Databases, Query } from "appwrite";
import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { indexOf } from "lodash";
import getFrenchTag from "../../helpers/local_text_helper";
import { Legend, RadialBar, RadialBarChart, ResponsiveContainer } from "recharts";
import contact_reason from "../../assets/images/contact-reason.png";
import logo_maaia from "../../assets/images/maaia-logo.png";
import { withTranslation } from "react-i18next";
const values = [
  "product",
  "product_availability",
  "product_advice",
  "product_problems",
  "price",
  "promotions",
  "order",
  "order_process",
  "payments",
  "refunds",
  "disputes",
  "billing",
  "delivery",
  "returns",
  "customer_account",
  "password",
  "security",
  "privacy",
  "customer_data",
  "after_sales",
  "technical_problems",
  "website_feedback",
  "special_programs",
  "affiliation",
  "customer_loyalty",
  "feedback",
  "complaints",
  "company_information",
  "ethics_social_responsibility",
  "subscription_management",
  "store_services",
  "influencers",
  "content_creators",
  "spam",
  "other",
];

const stringToColour = (str) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};
let colorMap;
values.forEach((value) => {
  if (colorMap) {
    colorMap[value] = stringToColour(value);
  } else {
    colorMap = {};
    colorMap[value] = stringToColour(value);
  }
});

const TagsList = (props) => {
  //  const RadialChartData = {
  //   options: {
  //     labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
  //     colors: ["rgb(61, 142, 248)", "rgb(17, 196, 110)", "#f1b44c", "#f46a6a", "rgb(239, 242, 247)"],
  //     legend: {
  //       show: true,
  //       position: "bottom",
  //       horizontalAlign: "center",
  //       verticalAlign: "middle",
  //       floating: false,
  //       fontSize: "14px",
  //       offsetX: 0,
  //       offsetY: -10,
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 600,
  //         options: {
  //           chart: {
  //             height: 240,
  //           },
  //           legend: {
  //             show: false,
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   series: contactReasonsValueList,
  // };

  const theMessages = props.messages;
  // const theMessages = props.messages.slice(-7);
  // console.log(" theMessages is: ", theMessages);

  // let RadialChartData = null;
  const navigate = useNavigate();
  const contactReasonsMap = {};

  const [contactReasonsValueList, setcontactReasonsValueList] = useState();
  const [contactReasonsObjectList, setContactReasonsObjectList] = useState(undefined);
  const [contactReasonsKeyList, setcontactReasonsKeyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDates, setSelectedDates] = useState([Date.now() - 7 * 24 * 60 * 60 * 1000, Date.now()]);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleDateChange = (theData) => {
    console.log("theData is: ", theData);
    // if (theData.length === 0) {
    //   contactReasonsMap['order'] = { val: 0, total: 1 };
    //   contactReasonsMap['product'] = { val: 0, total: 1 };
    //   contactReasonsMap['refunds'] = { val: 0, total: 1 };
    //   contactReasonsMap['payments'] = { val: 0, total: 1 };
    // } else {
    theData.forEach((message) => {
      const contactReasons = message.contact_reasons;
      // console.log("contactReasons is: ", contactReasons);
      contactReasons.forEach((reason) => {
        if (contactReasonsMap[reason]) {
          const theVal = message.process_state === "Treated" ? contactReasonsMap[reason].val + 1 : contactReasonsMap[reason].val;
          const theTotal = contactReasonsMap[reason].val + 1;
          contactReasonsMap[reason].val = theVal;
          contactReasonsMap[reason].total = theTotal;
          // console.log(
          //   `message.process_state is => ${JSON.stringify(message.process_state)} and contactReasonsMap[reason] is =>  ${JSON.stringify(
          //     contactReasonsMap[reason],
          //   )}`,
          // );
          //       contactReasonsMap[reason] = {val:  message.process_state === 'treated' ? ((contactReasonsMap[reason].val + 1) * 100)/contactReasonsMap[reason].total  : ((contactReasonsMap[reason].val + 1) * 100)/contactReasonsMap[reason].total, total: contactReasonsMap[reason].total + 1};
        } else {
          contactReasonsMap[reason] = { val: message.process_state === "treated" ? 1 : 0, total: 1 };
        }
      });
    });
    // }
    // contactReasonsMap['total'] = Object.values(contactReasonsMap).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // console.log("contactReasonsMap is: ", contactReasonsMap);
    // console.log("contactReasonsMap.values() is: ", Object.values(contactReasonsMap));
    // console.log("contactReasonsMap.keys() is: ", Object.keys(contactReasonsMap));
    setcontactReasonsValueList(Object.values(contactReasonsMap));
    setcontactReasonsKeyList(Object.keys(contactReasonsMap));
    setLoading(false);

    /*

      const data = [];
      Object.keys(contactReasonsMap).forEach((reason) => {
        data.push({
          name: getFrenchTag(reason),
          // value: (contactReasonsMap[reason]*100)/contactReasonsMap['total'],
          value : contactReasonsMap[reason],
          fill: colorMap[reason],
        },)
      });
      console.log("data is: ", data);
      data.sort((a, b) => b.value - a.value);
      const top10Elements = data.slice(0, 6);
      const top10ElementsWithoutTotal = top10Elements.filter((element) => element.name !== "Total");
      console.log("top 5 elements of data is: ", top10ElementsWithoutTotal);
      setContactReasonsObjectList(top10ElementsWithoutTotal);
      */

    const values_data = Object.values(contactReasonsMap);
    values_data.sort((a, b) => b.val < a.val);
    const top10_values_data_Elements = values_data.slice(0, 5).map((element) => ((element.val * 100) / element.total).toFixed(0));
    // const top10_values_data_ElementsWithoutTotal = top10_values_data_Elements.filter((element) => element.name !== "Total");

    // console.log("top10_values_data_Elements is: ", top10_values_data_Elements);

    const keys_data = Object.keys(contactReasonsMap);
    keys_data.sort((a, b) => b.val < a.val);
    const top10_keys_data_Elements = keys_data.slice(0, 5);
    // const top10_keys_data_ElementsWithoutTotal = top10_keys_data_Elements.filter((element) => element!== "Total");

    // console.log("top10_keys_data_Elements is: ", top10_keys_data_Elements);

    const RadialChartData = {
      series: top10_values_data_Elements,
      options: {
        stroke: {
          width: 0.1,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "60%",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
              },
              value: {
                fontSize: "16px",
              },
            },
          },
        },

        labels: top10_keys_data_Elements.map((element) => getFrenchTag(element)),
        colors: top10_keys_data_Elements.map((element) => stringToColour(element)),
      },
    };
    // console.log("RadialChartData is: ", RadialChartData);
    setContactReasonsObjectList(RadialChartData);
  };
  //handleDateChange(theMessages);

  // if (loading) {
  //   // Use the existing RadialChartData when loading is false
  //   RadialChartData = {
  //     options: {
  //       labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
  //       colors: [
  //         "rgb(61, 142, 248)",
  //         "rgb(17, 196, 110)",
  //         "#f1b44c",
  //         "#f46a6a",
  //         "rgb(239, 242, 247)",
  //       ],
  //       legend: {
  //         show: true,
  //         position: "bottom",
  //         horizontalAlign: "center",
  //         verticalAlign: "middle",
  //         floating: false,
  //         fontSize: "14px",
  //         offsetX: 0,
  //         offsetY: -10,
  //       },
  //       responsive: [
  //         {
  //           breakpoint: 600,
  //           options: {
  //             chart: {
  //               height: 240,
  //             },
  //             legend: {
  //               show: false,
  //             },
  //           },
  //         },
  //       ],
  //       plotOptions: {
  //         radialBar: {
  //           dataLabels: {
  //             name: {
  //               fontSize: "22px",
  //             },
  //             value: {
  //               fontSize: "16px",
  //             },
  //           },
  //         },
  //       },
  //     },
  //     series: [4, 15, 5],
  //   };
  // } else {
  //   if (contactReasonsKeyList) {
  //     const colors = contactReasonsKeyList.map((label) => colorMap[label]);
  //     const defaultColor = "rgb(239, 242, 247)";
  //     colors.forEach((color, index) => {
  //       if (!color) {
  //         colors[index] = defaultColor;
  //       }
  //     });
  //     const translatedProcessStateKeyList = contactReasonsKeyList.map((tag) =>
  //       getFrenchTag(tag)
  //     );
  //     RadialChartData = {
  //       options: {
  //         labels: translatedProcessStateKeyList,
  //         colors: colors,
  //         legend: {
  //           show: true,
  //           position: "bottom",
  //           horizontalAlign: "center",
  //           verticalAlign: "middle",
  //           floating: false,
  //           fontSize: "14px",
  //           offsetX: 0,
  //           offsetY: -10,
  //         },
  //         responsive: [
  //           {
  //             breakpoint: 600,
  //             options: {
  //               chart: {
  //                 height: 240,
  //               },
  //               legend: {
  //                 show: false,
  //               },
  //             },
  //           },
  //         ],
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "22px",
  //               },
  //               value: {
  //                 fontSize: "16px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //       series: contactReasonsValueList,
  //     };
  //   } else {
  //     RadialChartData = {
  //       options: {
  //         labels: [],
  //         colors: [],
  //         legend: {
  //           show: true,
  //           position: "bottom",
  //           horizontalAlign: "center",
  //           verticalAlign: "middle",
  //           floating: false,
  //           fontSize: "14px",
  //           offsetX: 0,
  //           offsetY: -10,
  //         },
  //         responsive: [
  //           {
  //             breakpoint: 600,
  //             options: {
  //               chart: {
  //                 height: 240,
  //               },
  //               legend: {
  //                 show: false,
  //               },
  //             },
  //           },
  //         ],
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "22px",
  //               },
  //               value: {
  //                 fontSize: "16px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //       series: [],
  //     };
  //   }
  // }
  useEffect(() => {
    // getMessageData([new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)), new Date()]);
    handleDateChange(theMessages);
  }, [theMessages]);

  return (
    <React.Fragment>
      {/* <Col lg={6}> */}

      <Row>
        <Col xl={12}>
          {loading ? (
            // Show this when loading is false
            <div>Loading</div>
          ) : (
            <>
              <div style={{ width: "100%", height: "300px" }}>
                {contactReasonsObjectList["series"].length > 0 && contactReasonsObjectList["series"].length ? (
                  <ResponsiveContainer>
                    {/* <RadialBarChart
                  cx="50%"
                  cy="50%"
                  innerRadius={20}
                  outerRadius={200}
                  barSize={4}
                  data={contactReasonsObjectList}
                >
                  <RadialBar
                    minAngle={15}
                    background
                    clockWise
                    dataKey="value"
                  />
                </RadialBarChart> */}
                    <ReactApexChart
                      options={
                        contactReasonsObjectList
                          ? contactReasonsObjectList["options"]
                          : {
                              stroke: {
                                width: 0.1,
                              },
                              plotOptions: {
                                radialBar: {
                                  hollow: {
                                    margin: 10,
                                    size: "70%",
                                  },
                                  dataLabels: {
                                    name: {
                                      fontSize: "22px",
                                    },
                                    value: {
                                      fontSize: "16px",
                                    },
                                  },
                                },
                              },

                              labels: ["Autre"],
                              colors: [stringToColour("product")],
                            }
                      }
                      series={contactReasonsObjectList ? contactReasonsObjectList["series"] : [0]}
                      type="radialBar"
                      height="330"
                      className="apex-charts"
                    />
                  </ResponsiveContainer>
                ) : (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                      backgroundImage: "url(" + contact_reason + ")",
                      height: "100%",
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}>
                    <div className="font-primary font-size-22 color-black mt-5">{props.t("En attente de données")}</div>
                    <img src={logo_maaia} height={40} />
                  </div>
                )}
              </div>
            </>
          )}
        </Col>
        <Col xl={12} className="">
          {!contactReasonsKeyList ? null : (
            <div className="d-flex flex-wrap justify-content-between">
              {contactReasonsKeyList.slice(0, 5).map((contact_reason, index) => (
                <React.Fragment key={index}>
                  <div style={{ display: "flex" }} key={index} className="align-items-center  mb-2">
                    <div
                      className=""
                      style={{
                        backgroundColor: stringToColour(contact_reason),
                        borderRadius: "2.269px",
                        width: "2.269px",
                        height: "12.855px",
                        borderRadius: "3.781px",
                        marginRight: "5px",
                      }}></div>
                    <div xl={11} className="legend-text simple-text font-primary">
                      {getFrenchTag(contact_reason)}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </Col>
      </Row>

      {/* </Col> */}
    </React.Fragment>
  );
};
export default withTranslation()(TagsList);
