import React from "react";

function AnalyticsOutlined() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9V9.5V9.5C12 9.96466 12 10.197 12.0384 10.3902C12.1962 11.1836 12.8164 11.8038 13.6098 11.9616C13.803 12 14.0353 12 14.5 12H20.4C20.9476 12 21.2215 12 21.452 11.8684C21.6353 11.7636 21.8175 11.55 21.8919 11.3523C21.9854 11.1039 21.9482 10.8714 21.8737 10.4063C21.1936 6.1599 17.8401 2.8064 13.5937 2.12631C13.1286 2.05181 12.8961 2.01457 12.6477 2.10809C12.45 2.18249 12.2364 2.36467 12.1316 2.54805C12 2.77854 12 3.05236 12 3.6V5M9 2.45801C4.94289 3.73227 2 7.52256 2 12.0002C2 17.5231 6.47715 22.0002 12 22.0002C16.4776 22.0002 20.2679 19.0573 21.5422 15.0002"
        stroke="currentcolor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AnalyticsOutlined;
