import React from "react";

function WrenchGestalt({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.25469 5C8.46381 6.13383 8 7.51275 8 9C8 10.0736 8.06806 11.5907 8.5 12.5L2.5 18.5C2.42035 18.5797 2.38052 18.6195 2.34847 18.6547C1.65508 19.4176 1.65508 20.5824 2.34847 21.3453C2.38052 21.3805 2.42035 21.4203 2.5 21.5V21.5C2.57965 21.5797 2.61948 21.6195 2.65474 21.6515C3.41756 22.3449 4.58244 22.3449 5.34526 21.6515C5.38052 21.6195 5.42035 21.5797 5.5 21.5L11.5 15.5C12.4093 15.9319 13.9264 16 15 16C18.866 16 22 12.866 22 9C22 8.30503 21.8987 7.63371 21.7101 7L19.2627 9.44738C18.4707 10.2394 18.0747 10.6354 17.618 10.7838C17.2163 10.9143 16.7837 10.9143 16.382 10.7838C15.9253 10.6354 15.5293 10.2394 14.7373 9.44738L14.5526 9.26274C13.7606 8.47071 13.3646 8.07469 13.2162 7.61803C13.0857 7.21635 13.0857 6.78365 13.2162 6.38197C13.3646 5.92531 13.7606 5.52929 14.5526 4.73726L17 2.28988C16.3663 2.10128 15.695 2 15 2C13.9264 2 12.9093 2.24169 12 2.67363"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default WrenchGestalt;
